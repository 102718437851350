<!-- 힐인선택 작업중 : 서동민 -->
<template>
	<div
		:class="[
			'cw-app-wrapper',
			'promotion',
			isPreContract ? 'pre-contract' : ''
		]"
	>
		<!-- 상단 Steppre -->
		<div class="cw-app-module">
			<MStepper />
		</div>
		<!--// 상단 Steppre -->

		<!--contents-->
		<!-- modals -->
		<v-dialog width="312" v-model="popPreSubsShow">
			<section class="u-confirm-wrap">
				<article class="panels">
					<v-card-title>
						사전계약 결합할인 적용 안내
					</v-card-title>
					<v-card-text>
						<ul class="u-confirm-text">
							<li>
								<p>
									결합할인 묶음 내 기존계정을 신규 사전계약 계정이 대체하여
									&nbsp;재렌탈 결합할인율 3%가 적용됩니다.
								</p>
							</li>
						</ul>
					</v-card-text>
				</article>
				<article class="bottom">
					<v-card-actions>
						<div class="col">
							<v-btn class="btn-confirm" @click="popPreSubsShow = false">
								닫기
							</v-btn>
						</div>
					</v-card-actions>
				</article>
			</section>
		</v-dialog>
		<!-- //modals -->
		<div class="cw-app-section">
			<div class="u-app-title">할인 선택</div>
			<div class="u-order-wrap">
				<div class="u-order-head">
					<div class="box">
						<div class="cnt">
							총
							<em>
								{{ productCount }}
							</em>
							대
						</div>
						<div class="btns">
							<v-btn class="btn-xs-size" @click="policySettingProducts"
								>자동 할인 적용</v-btn
							>
							<v-btn class="btn-xs-icon" @click="deleteAlldProduct"
								>전체삭제</v-btn
							>
						</div>
					</div>
				</div>

				<div class="u-order-block">
					<!-- 주문 리스트 -->
					<template v-for="(product, index) in products">
						<!-- 상품정보 -->
						<div :key="index">
							<div class="u-order-list">
								<div class="itembox">
									<div class="head" :id="`order_${index}`">
										<h3 class="tit">
											주문<em>{{ index + 1 }}</em>
										</h3>
										<v-btn class="btn-xs-icon" @click="deleteProduct(index)">
											삭제
										</v-btn>
									</div>
									<div class="label bg">선택한 상품</div>
									<div class="item">
										<dl>
											<dt>
												<img
													:src="`${product.prd.color_file_path}`"
													:alt="product.prd.leader_model_nm"
												/>
											</dt>
											<dd>
												<h4 class="type">
													<span>{{ product.options.sellType.text }}</span>
													<template v-if="product.options.agreeTerm">
														{{
															`${product.options.agreeTerm &&
																product.options.agreeTerm.USE_DUTY_MONTH}개월`
														}}
													</template>
													<template v-lese></template>
												</h4>

												<h3 class="tit">{{ product.prd.leader_model_nm }}</h3>
												<h5 class="desc">
													{{ product.prd.prod_nm }}, {{ product.prd.erp_cd }}
												</h5>
											</dd>
										</dl>
									</div>
								</div>
							</div>
							<!--// 상품정보 -->

							<!-- 선택옵션 -->
							<div class="u-order-options">
								<v-expansion-panels focusable>
									<v-expansion-panel>
										<v-expansion-panel-header>
											<h4>선택한 옵션</h4>
										</v-expansion-panel-header>
										<v-expansion-panel-content>
											<ul
												class="message"
												v-if="displayOption(product.options).length !== 0"
											>
												<li
													v-for="(item, i) in displayOption(product.options)"
													:key="`option_${i}`"
												>
													<p>
														<em>{{ item.name }}</em>
														{{ item.value }}
													</p>
												</li>
											</ul>
											<ul
												class="message"
												v-if="displayOption(product.payOptions).length !== 0"
											>
												<li
													v-for="(item, i) in displayOption(product.payOptions)"
													:key="`option_${i}`"
												>
													<p>
														<em>{{ item.name }}</em>
														{{ item.value }}
													</p>
												</li>
											</ul>
											<ul
												class="message"
												v-if="displayOption(product.options).length === 0"
											>
												<li>
													<p>선택한 옵션이 없습니다.</p>
												</li>
											</ul>
										</v-expansion-panel-content>
									</v-expansion-panel>
								</v-expansion-panels>
							</div>
							<!--// 선택옵션 -->

							<!-- 할인 적용 -->
							<div class="u-order-desc">
								<div class="box charge">
									<div class="label pb08">할인 적용</div>
									<!-- 동시구매 할인 적용 -->
									<div
										class="desc"
										:class="{ disabled: !product.payOptions.packDisplay }"
									>
										<div
											v-if="
												product.payOptions.packDisplay &&
													product.payOptions.packActive
											"
										>
											<p
												v-if="
													product.payOptions.ET_INPUT.PACKAGE_RATE === '' &&
														product.payOptions.ET_INPUT.PACKAGE_RATE === '0.00'
												"
											>
												동시구매할인 적용<em>
													{{
														product.payOptions.ET_INPUT.PACKAGE_RATE | comma
													}}%</em
												>
											</p>
											<p v-else>
												동시구매할인 적용<em>
													-{{
														product.payOptions.ET_INPUT.PACKAGE_RATE | comma
													}}%</em
												>
											</p>
											<v-btn
												class="btn-md-radius"
												@click="prdSameDiscountPayment"
												>설정</v-btn
											>
										</div>

										<div
											v-else-if="
												product.payOptions.packDisplay &&
													!product.payOptions.packActive
											"
										>
											<p>
												동시구매할인 미적용
											</p>
											<v-btn
												class="btn-md-radius"
												@click="prdSameDiscountPayment"
												>설정</v-btn
											>
										</div>
										<div v-else>
											<p>
												동시구매할인 적용불가
											</p>
											<v-btn
												class="btn-md-radius"
												:disabled="!product.payOptions.packDisplay"
												>설정</v-btn
											>
										</div>
									</div>
									<!--// 동시구매 할인 적용 -->
									<!-- 결합요금제 할인 적용 -->
									<div
										class="desc"
										:class="{ disabled: !product.payOptions.combiDisplay }"
									>
										<div
											v-if="
												product.payOptions.combiDisplay &&
													product.payOptions.combiActive
											"
										>
											<p
												v-if="
													product.payOptions.ET_INPUT.DC_RATE === '' ||
														product.payOptions.ET_INPUT.DC_RATE === '0.00'
												"
											>
												결합할인 적용<em>
													{{ product.payOptions.ET_INPUT.DC_RATE | comma }}%</em
												>
											</p>
											<p v-else>
												결합할인 적용<em>
													-{{
														product.payOptions.ET_INPUT.DC_RATE | comma
													}}%</em
												>
											</p>
											<v-btn
												v-if="isPreContract"
												icon
												class="btn-ico-mark"
												@click="preSubGuideOpen()"
											>
												<em class="ir">팝업 열기</em>
											</v-btn>
											<v-btn
												v-else
												class="btn-md-radius"
												@click="prdDiscountPayment"
												>설정</v-btn
											>
										</div>

										<div
											v-else-if="
												product.payOptions.combiDisplay &&
													!product.payOptions.combiActive
											"
										>
											<p>
												결합할인 미적용
											</p>
											<v-btn
												:disabled="isPreContract"
												class="btn-md-radius"
												@click="prdDiscountPayment"
												>설정</v-btn
											>
										</div>
										<div v-else>
											<p>
												결합할인 적용불가
											</p>
											<v-btn
												class="btn-md-radius"
												:disabled="!product.payOptions.combiDisplay"
											>
												설정
											</v-btn>
										</div>
									</div>
									<!--// 결합요금제 할인 적용 -->
									<!-- 페스타 패키지 할인 적용 2024/07 CWMJPRO-709 페스타 패키지 임시 미사용-->
									<div
										v-if="false"
										class="desc"
										:class="{ disabled: !product.payOptions.festaDisplay }"
									>
										<div
											v-if="
												product.payOptions.festaDisplay &&
													product.payOptions.festaActive
											"
										>
											<p
												v-if="
													product.payOptions.ET_INPUT.PACKAGE_RATE === '' ||
														product.payOptions.ET_INPUT.PACKAGE_RATE === '0.00'
												"
											>
												페스타 패키지 적용<em>
													{{
														product.payOptions.ET_INPUT.PACKAGE_RATE | comma
													}}%</em
												>
											</p>
											<p v-else>
												페스타 패키지 적용<em>
													-{{
														product.payOptions.ET_INPUT.PACKAGE_RATE | comma
													}}%</em
												>
											</p>
											<v-btn class="btn-md-radius" @click="prdFestaPayment"
												>설정</v-btn
											>
										</div>

										<div
											v-else-if="
												product.payOptions.festaDisplay &&
													!product.payOptions.festaActive
											"
										>
											<p>
												페스타 패키지 미적용
											</p>
											<v-btn
												:disabled="isPreContract"
												class="btn-md-radius"
												@click="prdFestaPayment"
												>설정</v-btn
											>
										</div>
										<div v-else>
											<p>
												페스타 패키지 적용불가
											</p>
											<v-btn
												class="btn-md-radius"
												:disabled="!product.payOptions.festaDisplay"
											>
												설정
											</v-btn>
										</div>
									</div>
									<!--// 페스타 패키지 할인 적용 -->
									<!-- 코웨이 패키지 할인 적용 -->
									<div
										class="desc"
										:class="{ disabled: !product.payOptions.fifteenDisplay }"
										v-if="product.payOptions.fifteenDisplay"
									>
										<div
											v-if="
												product.payOptions.fifteenDisplay &&
													product.payOptions.fifteenActive
											"
										>
											<p
												v-if="
													product.payOptions.ET_INPUT.PACKAGE_RATE === '' ||
														product.payOptions.ET_INPUT.PACKAGE_RATE === '0.00'
												"
											>
												코웨이 패키지 할인 적용<em>
													{{
														product.payOptions.ET_INPUT.PACKAGE_RATE | comma
													}}%</em
												>
											</p>
											<p v-else>
												코웨이 패키지 할인 적용<em>
													-{{
														product.payOptions.ET_INPUT.PACKAGE_RATE | comma
													}}%</em
												>
											</p>
											<v-btn class="btn-md-radius" @click="prdFifteenPayment"
												>설정</v-btn
											>
										</div>

										<div
											v-else-if="
												product.payOptions.fifteenDisplay &&
													!product.payOptions.fifteenActive
											"
										>
											<p>
												코웨이 패키지 할인 미적용
											</p>
											<v-btn class="btn-md-radius" @click="prdFifteenPayment"
												>설정</v-btn
											>
										</div>
										<div v-else>
											<p>
												코웨이 패키지 할인 적용불가
											</p>
											<v-btn
												class="btn-md-radius"
												:disabled="!product.payOptions.fifteenDisplay"
											>
												설정
											</v-btn>
										</div>
									</div>
									<!--// 코웨이 패키지 할인 적용 -->
									<!-- 포커스 이동을 위한 숨김 input -->
									<input
										type="text"
										style="display: block;position: absolute; width: 0px; height: 0px"
										:ref="`order_focus_${index}`"
									/>
									<!--// 포커스 이동을 위한 숨김 input -->
									<div class="select">
										<!-- 정기결제할인 -->
										<div>
											<v-btn
												v-if="product.regularPayment"
												:class="{ active: product.regularPayment }"
											>
												<template>
													정기결제할인
													<span class="btn_value">
														<b class="primary--text">-1,000원/월</b>
													</span>
												</template>
											</v-btn>
										</div>
										<!-- //정기결제할인 -->

										<!-- 주문유형 -->
										<template
											v-if="
												product.payOptions.ET_INPUT &&
													['047', '048', '049', '050'].includes(
														product.payOptions.ET_INPUT.PACKAGE_ID
													) &&
													!['Z002', 'Z003'].includes(product.esProd.ORD_TYPE) &&
													['PK', 'FP', 'FT'].includes(
														product.payOptions.orderType
													)
											"
										>
											<div>
												<v-btn
													:class="{
														active: ['047', '048', '049', '050'].includes(
															product.payOptions.ET_INPUT.PACKAGE_ID
														)
													}"
												>
													<template>
														PRE-PASS
														<span class="btn_value">
															<b class="primary--text">-100,000원</b>
														</span>
													</template>
												</v-btn>
											</div>
										</template>

										<template
											v-else-if="product.options.sellType.code === 'Z001'"
										>
											<MSelectBottom3
												title="주문유형"
												:items="orderTypeItems1"
												v-model="product.payOptions.orderType"
												:value="product.payOptions.orderType"
												@item-selected="handleOrderTypeSelected(index, $event)"
												v-if="
													productSaleInfo !== null &&
														Number(productSaleInfo.E_OUTPUT.PASS_CNT) !== 0
												"
											>
												<template v-if="product.payOptions.orderType === 'P'">
													<span class="btn_tit">Pre-Pass</span>
													<span class="btn_value">
														<b class="primary--text">등록비-100,000원</b>
													</span>
												</template>
												<template
													v-else-if="product.payOptions.orderType === undefined"
												>
													주문유형 선택
													<span class="btn_value"></span>
												</template>
												<template v-else>
													{{ orderTypeDisplayText(product) }}
													<span class="btn_value"></span>
												</template>
											</MSelectBottom3>

											<MSelectBottom3
												v-else
												title="주문유형"
												:items="orderTypeItems2"
												v-model="product.payOptions.orderType"
												:value="product.payOptions.orderType"
												@item-selected="handleOrderTypeSelected(index, $event)"
											>
												<template
													v-if="product.payOptions.orderType === undefined"
												>
													주문유형 선택
													<span class="btn_value"></span>
												</template>
												<template v-else>
													{{ orderTypeDisplayText(product) }}
													<span class="btn_value"></span>
												</template>
											</MSelectBottom3>

											<CRPExistingOrderBottom
												title="기존주문번호 선택"
												v-model="product.payOptions.crpExistingOrder"
												@item-selected="handleCRPExistingOrder(index, $event)"
												:hideButton="true"
												:persistent="true"
												ref="ceo"
											/>
										</template>
										<!--// 주문유형-->

										<!--// 등록비납부 -->

										<!-- 프로모션할인 -->
										<template
											v-if="
												product.payOptions.returnPromotionApplied === false &&
													product.payOptions.ET_ZSDS5204 &&
													product.payOptions.ET_ZSDS5204.length !== 0
											"
										>
											<PromotionDiscountBottom
												v-model="product.payOptions.promotion"
												:promotions="product.payOptions.ET_ZSDS5204"
												:isAllatOnce="isAllatOnce(product)"
												@item-selected="
													handlePromotionDiscountSelected(index, $event)
												"
											>
												<!-- 선택전 -->
												<template
													v-if="product.payOptions.promotion === undefined"
												>
													프로모션 할인 선택
													<span class="btn_value"></span>
												</template>
												<!-- 선택전 -->

												<!-- 선택 가능한 프로모션이 없음 -->
												<template
													v-if="
														product.payOptions.ET_ZSDS5204 &&
															product.payOptions.ET_ZSDS5204.length === 0
													"
												>
													적용가능 프로모션
													<span class="btn_value">
														<b class="primary--text ml-1">
															없음
														</b>
													</span>
												</template>
												<!-- 선택 가능한 프로모션이 없음 -->

												<!-- 선택 안함 -->
												<template
													v-else-if="product.payOptions.promotion === ''"
												>
													프로모션 선택 없음
													<span class="btn_value">
														<b class="primary--text ml-1"></b>
													</span>
												</template>
												<!-- 선택 안함 -->
												<!-- 선택함 -->
												<template v-else>
													<span class="btn_tit">
														{{
															(product.payOptions.promotionName || '').slice(
																0,
																8
															)
														}}
													</span>
													<span class="btn_value">
														<b class="primary--text ml-1">
															-{{ product.payOptions.promotionDcAmt | comma }}
															<template v-if="isAllatOnce(product)">
																원
															</template>
															<template v-else>
																원/월
															</template>
														</b>
													</span>
												</template>
												<!--// 선택함 -->
											</PromotionDiscountBottom>
										</template>

										<template
											v-else-if="
												product.payOptions.returnPromotionApplied === false
											"
										>
											<div>
												<v-btn class="active">
													<template>
														적용가능 프로모션 없음
														<span class="btn_value">
															<b class="primary--text"></b>
														</span>
													</template>
												</v-btn>
											</div>
										</template>
										<!--// 프로모션할인 -->

										<!-- 멤버십 선납할인 -->
										<MemberShipDiscountBottom
											v-if="
												shouldMembershipDisplayed(product) &&
													product.payOptions.membership
											"
											v-model="product.payOptions.msDc"
											title="멤버십 선납 할인 선택"
											:memAmts="getSelectedMembershipAmt(product)"
											:membership="product.payOptions.membership"
											:value="product.payOptions.msDc"
											:selectedMemberShipDiscountItem="
												product.payOptions.selectedMemberShipDiscountItem
											"
											@item-selected="
												handleMemberShipDiscountSelected(index, $event)
											"
										>
											<template
												v-if="
													product.payOptions.msDc === undefined ||
														product.payOptions.msDc === '00'
												"
											>
												<span class="btn_tit">멤버십 선납 할인 선택</span>
												<span class="btn_value">
													<b class="primary--text ml-1">
														없음
													</b>
												</span>
											</template>

											<template v-else-if="product.payOptions.msDc === '01'">
												<span class="btn_tit">1년선납</span>
												<span class="btn_value">
													<b class="text_orange mr-1"
														>+{{ product.payOptions.msPreAmt | comma }}</b
													>/<b class="primary--text ml-1">
														(-{{ product.payOptions.msAmtDc | comma }}원 할인
														적용)</b
													></span
												>
											</template>

											<template v-else
												>멤버십 선납 할인 선택 <span class="btn_value"></span
											></template>
										</MemberShipDiscountBottom>
										<!-- 멤버십 선납할인 -->

										<!-- 렌탈 선납 할인 선택 -->
										<PopMessageBottomSheet
											title="선납 할인 선택"
											v-if="shouldPrepayMonthDisplayed(product)"
											v-model="product.payOptions.prepayMonth"
											:product="product"
											:prdMonthTotal="displayPrepayMonthAmt(product)"
											@item-selected="handlePrepayMonthSelected(index, $event)"
										>
											<template v-if="!product.payOptions.prepayMonth">
												선납 할인 선택
												<span class="btn_value"></span>
											</template>

											<template
												v-else-if="product.payOptions.prepayMonth !== '01'"
											>
												{{ product.payOptions.prepayMonth / 12 }}년
												<span class="btn_value">
													<b class="mr-1 text_orange">
														+ {{ calcPrepay(product) | comma }}
													</b>
													<b class="ml-1 primary--text">
														(-{{ calcPrepayDc(product) | comma }}원 할인 적용)
													</b>
												</span>
											</template>

											<template v-else>
												선납 할인 선택 없음
												<span class="btn_value">
													<b class="ml-1 primary--text"> </b>
												</span>
											</template>
										</PopMessageBottomSheet>
										<!--// 렌탈 선납 할인 선택 -->
										<PopDownRentalBottomSheet
											title="선납할인2 할인 선택"
											v-if="
												shouldPrepayMonthDisplayed(product) &&
													product.payOptions.ET_ZSDS6087 &&
													product.payOptions.ET_ZSDS6087.length != 0
											"
											v-model="product.payOptions.downRentalPrepayAmt"
											:product="product"
											:prdMonthTotal="displayPrepayMonthAmt(product)"
											:downRentalSelected="
												product.payOptions.downRentalSelected
											"
											@item-selected="handleDownRentalSelected(index, $event)"
										>
											<template
												v-if="product.payOptions.downRentalPrepayAmt === '0'"
											>
												선납할인2 할인 선택 없음
												<span class="btn_value">
													<b class="ml-1 primary--text"> </b>
												</span>
											</template>
											<template
												v-else-if="
													product.payOptions.downRentalPrepayAmt !== '0'
												"
											>
												<div class="mr-1 text_orange">
													+ {{ calcDownRental(product) | comma }}원 선납
												</div>
												<span class="btn_value">
													<b class="ml-1 primary--text">
														(-{{ calcDownRentalDc(product) | comma }}원 할인
														적용)
													</b>
												</span>
											</template>
											<template v-else>
												선납할인2 할인 선택
												<span class="btn_value"></span>
											</template>
										</PopDownRentalBottomSheet>

										<!-- 렌탈 계약금 입력 -->
										<div
											class="u-money-box"
											v-if="shouldEarnestMoneyDepositDisplayed(product)"
										>
											<h4 class="tit">계약금 입력</h4>
											<v-text-field
												v-model="product.payOptions.displayAcptAmt"
												class="input-money"
												single-line
												outlined
												@keypress="numberFormatDel"
												@focus="handleMinMaxAcptAmtFocus(product)"
												@blur="handleMinMaxAcptAmtBlur(product)"
											></v-text-field>
											<p class="msg" v-if="product.valueOptions">
												최소 금액
												{{ product.valueOptions.min | comma }}원 이상, 천원 단위
												입력 가능
											</p>
										</div>
										<!-- 렌탈 입력 -->

										<!--잔액할부-->
										<MSelectBottom3
											v-if="shouldEarnestMoneyDepositDisplayed(product)"
											title="잔액 할부 개월 선택"
											:items="calcInstallmentMonthItems(product)"
											v-model="product.payOptions.installmentMonth"
											:disabled="false"
											@item-selected="
												handleBalanceInstallmentMonthSelected(index, $event)
											"
										>
											<template v-if="product.payOptions.installmentMonth">
												<span class="btn_tit"
													>{{ product.payOptions.installmentMonth }}개월</span
												>
												<span class="btn_value"></span>
											</template>

											<template v-else>
												잔액 할부개월 선택
												<span class="btn_value"></span>
											</template>
										</MSelectBottom3>
										<!-- 잔액할부 -->
									</div>
								</div>

								<div
									class="box price"
									:class="{ active: calcPriceActive !== index }"
								>
									<div class="label">
										금액 계산
										<button
											type="button"
											class="btn-toggle"
											@click="onClickPriceCalculation(index)"
										></button>
									</div>

									<!-- 상품금액 -->
									<div class="u-accordion-box">
										<div class="u-price-total">
											<div>
												<span class="cate">상품금액</span>
												<span class="value">
													<em class="payment">
														{{
															displayPrdRecvAmt(product.saleInfo.prdAmt).string
														}}
													</em>
													<em class="month">
														{{
															displayPrdMonthAmt(product.saleInfo.prdAmt).string
														}}
													</em>
												</span>
											</div>

											<ul class="detail">
												<li
													v-for="(item, i) in product.saleInfo.prdAmt"
													:key="i"
												>
													<span class="cate">{{ item.name }}</span>
													<span class="value">
														<em class="payment" v-if="item.recvAmt !== ''"
															>{{ item.recvAmt | comma }}원</em
														>
														<em class="payment" v-else></em>
														<em class="month" v-if="item.monthAmt !== ''"
															>{{ item.monthAmt | comma }}원/월</em
														>
														<em class="month" v-else></em>
													</span>
												</li>
											</ul>
											<!--// 상품금액 -->

											<div>
												<span class="cate">할인금액</span>
												<span class="value">
													<em
														class="payment"
														v-if="
															displayPrdRecvAmt(product.saleInfo.prdAmt)
																.string !== ''
														"
													>
														{{
															displaySaleRecvAmt(product.saleInfo.saleAmt)
																.string
														}}
													</em>
													<em class="payment" v-else></em>

													<em
														class="month"
														v-if="
															displayPrdMonthAmt(product.saleInfo.prdAmt)
																.string !== ''
														"
													>
														{{
															displaySaleMonthAmt(product.saleInfo.saleAmt)
																.string
														}}
													</em>
													<em class="month" v-else></em>
												</span>
											</div>
											<ul class="detail">
												<li
													v-for="(item, i) in product.saleInfo.saleAmt"
													:key="i"
												>
													<span class="cate">{{ item.name }}</span>
													<span class="value">
														<em
															class="payment"
															v-if="
																item.name === '프로모션' &&
																	displayPrdRecvAmt(product.saleInfo.prdAmt)
																		.string === ''
															"
														></em>
														<em
															class="payment"
															v-else-if="
																item.recvAmt === '별도 혜택' &&
																	displayPrdRecvAmt(product.saleInfo.prdAmt)
																		.string === ''
															"
														></em>

														<em
															class="payment"
															v-else-if="
																item.recvAmt !== '' &&
																	item.type === 'number' &&
																	item.recvAmt === 0
															"
															>{{ item.recvAmt | comma }}원</em
														>
														<em
															class="payment"
															v-else-if="
																item.recvAmt !== '' &&
																	item.type === 'number' &&
																	item.recvAmt !== 0
															"
															>-{{ item.recvAmt | comma }}원</em
														>

														<em
															class="payment"
															v-else-if="
																item.recvAmt !== '' && item.type === 'string'
															"
															>{{ item.recvAmt }}</em
														>
														<em class="payment" v-else></em>

														<em
															class="month"
															v-if="
																item.name === '프로모션' &&
																	displayPrdMonthAmt(product.saleInfo.prdAmt)
																		.string === ''
															"
														></em>
														<em
															class="month"
															v-else-if="
																item.monthAmt === '별도 혜택' &&
																	displayPrdMonthAmt(product.saleInfo.prdAmt)
																		.string === ''
															"
														></em>

														<em
															class="month"
															v-else-if="
																item.monthAmt !== '' &&
																	item.type === 'number' &&
																	item.monthAmt === 0
															"
															>{{ item.monthAmt | comma }}원/월</em
														>
														<em
															class="month"
															v-else-if="
																item.monthAmt !== '' &&
																	item.type === 'number' &&
																	item.monthAmt !== 0
															"
															>-{{ item.monthAmt | comma }}원/월</em
														>

														<em
															class="month"
															v-else-if="
																item.monthAmt !== '' && item.type === 'string'
															"
															>{{ item.monthAmt }}</em
														>
														<em class="month" v-else></em>
													</span>
												</li>
											</ul>
										</div>
									</div>

									<div class="u-price-total">
										<div class="result">
											<span class="cate">총 금액</span>
											<span class="value">
												<em class="payment" isRentalOfSoldSeparately>
													{{
														displayPrdTotalAmt(product.saleInfo).prdRecvTotal
													}}</em
												>
												<em class="month">
													{{
														displayPrdTotalAmt(product.saleInfo).prdMonthTotal
													}}</em
												>
											</span>
										</div>
									</div>

									<div
										class="notice"
										v-if="
											product.payOptions.prepayMonth &&
												product.payOptions.prepayMonth !== '01'
										"
									>
										선납기간({{ product.payOptions.prepayMonth }}개월)은 렌탈료
										비용이 부과되지 않습니다.
									</div>
								</div>
							</div>
							<!--// 할인 적용 -->
						</div>
					</template>
					<!-- 주문 리스트 -->
				</div>

				<!-- 상품 합계 -->
				<div class="u-order-total">
					<div class="underline">상품 합계</div>
					<div class="u-price-total">
						<div>
							<span class="cate">주문상품 개수</span>
							<span class="value">{{ productCount }}개</span>
						</div>
						<div>
							<span class="cate">상품금액</span>
							<span class="value">
								<em class="payment">
									수납 {{ allPrdTotalAmt().prdRecvTotal | comma }}원
								</em>
								<em class="month">
									{{ allPrdTotalAmt().prdMonthTotal | comma }}원/월
								</em>
							</span>
						</div>
						<div>
							<span class="cate">할인금액</span>
							<span class="value">
								<em class="payment" v-if="allPrdTotalAmt().saleRecvTotal === 0">
									수납 {{ allPrdTotalAmt().saleRecvTotal | comma }}원
								</em>
								<em class="payment" v-else>
									수납 -{{ allPrdTotalAmt().saleRecvTotal | comma }}원
								</em>
								<em class="month" v-if="allPrdTotalAmt().saleMonthTotal === 0">
									{{ allPrdTotalAmt().saleMonthTotal | comma }}원/월
								</em>
								<em class="month" v-else>
									-{{ allPrdTotalAmt().saleMonthTotal | comma }}원/월
								</em>
							</span>
						</div>
						<div class="result">
							<span class="cate">총 금액</span>
							<span class="value">
								<em class="payment">
									수납 {{ allPrdTotalAmt().recvTotal | comma }}원
								</em>
								<em class="month">
									{{ allPrdTotalAmt().monthTotal | comma }}원/월
								</em>
							</span>
						</div>
					</div>
				</div>
				<!-- 상품 합계 -->

				<!-- 주문 시 유의사항 -->
				<div class="u-order-notice" :class="{ active: orderNoticeActive }">
					<h4 class="notice-title" @click="onClickToggleNotice">
						<span>주문 시 유의사항</span><i class="mdi mdi-menu-down"></i>
					</h4>
					<ul class="notice-list">
						<li>
							<p>
								14일 이내에 고객 변심으로 인한 제품 반환 시에는 제품 설치비
								5만원이 부과됩니다. (정수기, 공기청정기, 비데, 연수기,
								음식물처리기, 매트리스, 안마의자, 반신욕 사우나, 틸만
								전기레인지, 주스프레소 모두 해당)
							</p>
						</li>
						<li>
							<p>
								렌탈 제품의 약정기간은 3년이며, 중도 해약 시 잔여월 렌탈료의
								10%에 해당하는 금액이 위약금으로 발생합니다.
							</p>
						</li>
						<li>
							<p>
								신상품 재렌탈의 약정기간은 3년이며, "3년 약정"이 선택되어
								렌탈등록비 10만원이 할인됩니다. (단, 약정 기간 내 중도 해약 시
								할인 받은 금액이 위약금으로 발생합니다.)
							</p>
						</li>
						<li>
							<p>
								렌탈 제품의 최초 설치일로부터 5년 이후에는 제품의 소유권이
								고객님께 이전됩니다.(매트리스 렌탈 및 6년 약정 제품 제외)
							</p>
						</li>
						<li>
							<p>
								위의 기재된 요금은 회사 내부 사정에 따라서 변경될 수 있습니다.
							</p>
						</li>
					</ul>
				</div>
				<!--// 주문 시 유의사항 -->
			</div>
		</div>
		<!--//contents-->

		<!--bottom-->
		<div class="cw-app-fixed">
			<div class="u-btns-group">
				<div class="column">
					<v-btn class="u-btn-text" large @click="goPrev">이전</v-btn>
				</div>
				<div class="column">
					<v-btn class="u-btn-active" large @click="onRecpClick">다음</v-btn>
				</div>
			</div>
		</div>
		<!--//bottom-->
		<PopPaymentCombined ref="combi" @combiApplyProducts="combiApplyProducts" />
		<PaySameDiscountPayment ref="same" @packApplyProducts="packApplyProducts" />
		<PopFestaPackage ref="festa" @festaApplyProducts="festaApplyProducts" />
		<FifteenDiscountPayment
			ref="fifteen"
			:products="products"
			@fifteenApplyProducts="fifteenApplyProducts"
		/>
	</div>
</template>
<script>
import {
	fetchPayOptions,
	policySetting,
	policyCheck
} from '@/apis/order.pay.api'
import {
	postWebOrderInfoById,
	fetchWebOrderInfoById,
	mOrderInfoByEtc
} from '@/apis/order.status.api'
import {
	getSaleData,
	getCombiCheck,
	getPackCheck,
	getPackPrdhaCheck,
	getCombiPrdhaCheck,
	getMessageByStatusCode
} from '@/utils/ec'
import { isProduction } from '@/utils'
import { registerCallbackToApp } from '@/utils/navigation'
import { mapActions, mapState } from 'vuex'
import filters from '@/mixins/filters'
import computeds from '@/mixins/computeds'
import PopPaymentCombined from '@/views/pay/PopPaymentCombined'
import PaySameDiscountPayment from '@/views/pay/PaySameDiscountPayment'
import PopFestaPackage from '@/views/pay/PopFestaPackage'
import FifteenDiscountPayment from '@/views/pay/FifteenDiscountPayment'
import { getPreContractSalesChk } from '@/apis/pre.contract.api'
import { preMem } from '@/apis/order.inst.api'
import { isParameterEmpty } from '@/utils/common'

export default {
	name: 'PayPageSale',
	mixins: [filters, computeds],
	components: {
		PopPaymentCombined,
		PaySameDiscountPayment,
		PopFestaPackage,
		FifteenDiscountPayment
	},
	computed: {
		...mapState('pay', [
			'categories',
			'appendedPayServices',
			'discountedPayServices',
			'testMode'
		]),

		// 주문 상품 전체 수납 금액(상품 총 수납금액 - 상품 총 할인 금액)
		totalSumOfRecpAmt() {
			const amt = this.allProductAmount.sumOfRecpAmt // 상품 총 수납 금액
			const dcamt = this.allProductDcAmount.sumOfRecpDcAmt // 상품 총 할인 금액
			const totalSumOfRecpAmt = amt - dcamt
			return totalSumOfRecpAmt
		},

		// 상품 전체 수량
		productCount() {
			const sum = this.products.reduce((a, b) => a + b.payOptions.quantity, 0)
			return sum
		},

		// 전체 상품금액 합계
		allProductAmount() {
			let sumOfRecpAmt = this.allPrdTotalAmt().prdRecvTotal
			let sumOfMonthAmt = this.allPrdTotalAmt().prdMonthTotal

			return { sumOfRecpAmt, sumOfMonthAmt }
		},

		// 전체 할인금액 합계
		allProductDcAmount() {
			let sumOfRecpDcAmt = this.allPrdTotalAmt().saleRecvTotal
			let sumOfMonthDcAmt = this.allPrdTotalAmt().saleMonthTotal

			return { sumOfRecpDcAmt, sumOfMonthDcAmt }
		},

		// 상품금액 합계
		appendedPayServicesAmountsByAllCategories() {
			const amounts = this.categories
				.map(c => {
					return {
						category: c.code,
						name: c.name,
						amount: this.appendedPayServicesAmountByCategory(c)
					}
				})
				.filter(
					c =>
						c.amount.filter(
							amt => amt.sumOfRecpAmt > 0 || amt.sumOfMonthAmt > 0
						).length
				)
			return amounts
		},

		// 패키지항목 표시여부
		shouldPackageDisplayed() {
			return this.pkg.id
		},

		sumOfMonthDcAmt() {
			return this.allProductDcAmount.sumOfMonthDcAmt > 0
				? this.allProductDcAmount.sumOfMonthDcAmt
				: this.allProductDcAmount.sumOfMonthDcAmt
		},

		productCountForPackage() {
			return this.products.filter(
				p => !this.isRental(p) || p.payOptions.orderType === 'G'
			).length
		}
	},

	data() {
		return {
			calcPriceActive: null, // 금액 계산 오픈 여부
			orderNoticeActive: false, // 주문시 유의 사항
			products: [], // 상품 정보
			productSaleInfo: null,
			orderTypeItems1: [
				{ code: 'P', text: 'Pre-Pass' },
				{ code: 'G', text: '일반 (등록비 일시불)' }
			],
			orderTypeItems2: [{ code: 'G', text: '일반' }],
			orderTypeItemList: [
				{ code: 'P', text: 'Pre-Pass' },
				{ code: 'G', text: '일반' },
				{ code: 'PK', text: '할인요금제 적용 할인' },
				{ code: 'FP', text: '페스타 패키지 할인' },
				{ code: 'FT', text: '코웨이 패키지 할인' }
			],

			regFeePayMethodItems: [
				{ code: 'A', text: '등록비 일시불' },
				{ code: 'L', text: '등록비 분납' }
			],
			prepayMonthItems: [
				{ code: '01', text: '선납 할인 선택 없음' },
				{ code: '12', text: '1년' },
				{ code: '24', text: '2년' },
				{ code: '36', text: '3년' }
			],
			installmentMonthItems: [
				{ code: 3, text: '3개월' },
				{ code: 6, text: '6개월' },
				{ code: 12, text: '12개월' },
				{ code: 36, text: '36개월' },
				{ code: 60, text: '60개월' }
			],
			options: { precision: 0, length: 8 },
			properties: { inputmode: 'numeric', maxLength: 10 },
			//dcRate: 일시불금액 할인율, dcAmt:등록비할인 금액, dcRateR: 렌탈료 할인율
			pkg: { id: '', name: '', dcRate: '0', dcAmt: 0, dcRateR: 0 },
			isProduction: isProduction(),
			popPreSubsShow: false,
			isPreContract: this.$store.state['verify'].customer.orderType === 'C'
		}
	},
	created() {
		//this.$store.dispatch('pay/delAllPrd')
		this.initAppPage()
	},
	async mounted() {
		/**
		 * 진입한 곳에 따라 POLICY_SETTING 다시 적용할 수 있도록 수정
		 */

		const { name } = this.$store.state.route.from
		const { status } = this.$route.query
		let changeStatue = false
		this.$log('ROUTE NAME : ', name, 'STATUS : ', status, changeStatue)

		const mobOrderNo = this.$store.getters['verify/mobOrderNo']
		if (this.isParameterEmpty(mobOrderNo)) return
		// DB 조회는 105(결제입력) 이후인 상태만
		// 상품정보가 DB에 저장되는 시점은 할인선택 완료되는 시점이기때문
		if ((status && status !== 110) || status >= 105) {
			try {
				const res = await fetchWebOrderInfoById(mobOrderNo) // 웹 주문 정보 조회
				const { orderInfoJson } = res.resultObject

				const {
					products,
					pay,
					combiApplyPrd,
					combiExistingPrd,
					combiExistingApplyPrd,
					packagePrd
				} = JSON.parse(orderInfoJson)

				const data = {
					...this.$data,
					...pay
				}

				if (!data.productSaleInfo && data.productSaleInfo === null) {
					changeStatue = true
					this.products = products
					this.$store.dispatch('pay/savePayPrd', this.products)
				} else {
					changeStatue = false
					this.products = data.products
					this.$store.dispatch('pay/savePayPrd', this.products)
					await this.$store.dispatch(
						'pay/saveProductSaleInfo',
						data.productSaleInfo
					)

					this.productSaleInfo = data.productSaleInfo

					if (combiApplyPrd) {
						await this.$store.dispatch('pay/saveApplyPrdAll', combiApplyPrd)
					}

					if (combiExistingPrd) {
						await this.$store.dispatch(
							'pay/saveCombiExistingPrdAll',
							combiExistingPrd
						)
					}

					if (combiExistingApplyPrd) {
						await this.$store.dispatch(
							'pay/saveCombiExistingApplyPrd',
							combiExistingApplyPrd
						)
					}

					if (packagePrd) {
						await this.$store.dispatch('pay/savePackagePrd', packagePrd)
					}

					return
				}
			} catch (err) {
				this.$log(err)
			}
		}

		//this.$store.dispatch('pay/savePayPrd', payProducts)
		this.products = this.$store.getters['pay/payProducts'].map(p => {
			if (p.payOptions.orderType === 'P') {
				this.addIfNotExist(p.payOptions, 'priceDcTypes', '01')
			}

			if (p.options.prdOption1 && p.options.prdOption1.code) {
				// 부가서비스 선택시
				this.addIfNotExist(p.payOptions, 'priceAddTypes', '03')
				this.addIfNotExist(p.payOptions, 'priceAddTypes', '01')
			} else {
				// 부가서비스 없음
				this.$set(
					p.payOptions,
					'priceAddTypes',
					p.payOptions.priceAddTypes.filter(x => x !== '01')
				)
			}

			if (p.payOptions.returnPenaltyAmt) {
				this.addIfNotExist(p.payOptions, 'priceAddTypes', '07')
			}

			this.addIfNotExist(p.payOptions, 'saleInfo', [])

			// 상품선택에서 선택된 멤버십이 있으면 멤버십 가입처리
			if (p.options.membershipType) {
				this.applyMembershipJoin(p, {
					code: p.options.membershipType.code,
					amt: p.options.membershipType.MON_AMT,
					useTp: p.options.membershipType.USE_TP
				})
			}

			let regularPayment = false
			if (this.isRental(p)) {
				regularPayment = true
			}
			return {
				...p,
				active: true,
				/*saleInfo: SALE_INFO,*/
				regularPayment: regularPayment
			}
		})
		await this.setupPayOptions()

		if (name === 'prd-search') {
			await this.policySettingReq() // 자동할인 적용
		}
	},

	// 다음  - 수납정도
	methods: {
		getSaleData,
		getPackCheck,
		getCombiCheck,
		getPackPrdhaCheck,
		getCombiPrdhaCheck,
		getMessageByStatusCode,
		isParameterEmpty,
		...mapActions({ showSnackbar: 'common/showSnackbar' }),

		displayOption(options) {
			const optionList = []

			if (options.mngType) {
				optionList.push({ name: '관리유형', value: options.mngType.text })
			}

			if (options.membershipType) {
				if (options.membershipType.code === '') {
					optionList.push({ name: '멤버십', value: '추가 안함' })
				} else {
					optionList.push({
						name: '멤버십',
						value: options.membershipType.text
					})
				}
			}

			if (options.filterType) {
				optionList.push({ name: '필터선택1', value: options.filterType.text })
			}

			if (options.filterType2) {
				optionList.push({ name: '필터선택2', value: options.filterType2.text })
			}

			if (options.filterType3) {
				optionList.push({ name: '필터선택3', value: options.filterType3.text })
			}

			if (options.filterType4) {
				optionList.push({ name: '필터선택4', value: options.filterType4.text })
			}

			if (options.hangerType) {
				optionList.push({ name: '추가선택1', value: options.hangerType.text })
			}

			if (options.hangerType2) {
				optionList.push({ name: '추가선택2', value: options.hangerType2.text })
			}

			if (options.setupType) {
				optionList.push({ name: '설치방식', value: options.setupType.text })
			}

			if (options.freeStand) {
				optionList.push({ name: '설치방식', value: options.freeStand.text })
			}

			if (options.prdOption2) {
				if (options.prdOption2.code === '') {
					optionList.push({ name: '별매상품', value: '추가 안함' })
				} else {
					const contract = this.displayPrdOption2Item(options.prdOption2)
						.contract
					const name = this.displayPrdOption2Item(options.prdOption2).name

					optionList.push({ name: '별매상품', value: contract + name })
				}
			}

			if (options.prdOption1) {
				if (options.prdOption1.code === '') {
					optionList.push({ name: '부가서비스', value: '추가 안함' })
				} else {
					optionList.push({
						name: '부가서비스',
						value: options.prdOption1.text
					})
				}
			}

			return optionList
		},

		// 렌탈, 재렌탈, 일시불 구분 (SALE_GB = N : 렌탈, R: 재랜탈, 빈값 : 일시불)
		getDiscountRentalType(param) {
			if (param === 'N') {
				return { code: 'Z001', text: '렌탈' }
			} else if (param === 'R') {
				return { code: 'Z002', text: '재렌탈' }
			} else if (param === '') {
				return { code: 'Z003', text: '일시불' }
			} else {
				return { code: '', text: '' }
			}
		},

		/**
		 * 할인정보 유효성 체크
		 * @returns {Promise<*>}
		 * @discription v1/03/07,ZSD_1000_MOBILE_POLICY_CHECK
		 *              사용자가 설정한 할인 정보에 대한 데이터(payOptions.ET_INPUT)를 기준으로 유효성 체크를 진행한다.
		 *              COMBILIST_01 또는 IT_INPUT 중에 비할인 계정(DC_RATE:0.00, COMBI_GB: 'O'인 계정 1개는 필수)
		 */
		async prdSaleInfoValidCheck() {
			const IT_INPUT = []
			let IT_COMBLIST_01 = []
			const IT_PACKLIST_01 = []

			let combiApplyPrd = []

			// 기존 동시구매 적용되지 않은 주문
			const itPackListTemp = this.$store.getters['pay/getPackagePrd']
			if (itPackListTemp.length !== 0) {
				itPackListTemp.forEach(v => {
					if (v.packActive && v.PACKAGE_ID === '047') {
						// 동시구매 대상이고, 동시구매 적용
						IT_PACKLIST_01.push({
							ORDER_NO: v.ORDER_NO,
							PACKAGE_ID: v.PACKAGE_ID,
							DC_AMT: v.DC_AMT,
							PACKAGE_RATE: v.PACKAGE_RATE
						})
					}
				})
			}

			// 기존 결합 적용되지 않은 주문
			const itCombiListTemp = this.$store.getters['pay/getCombiExistingPrd']
			itCombiListTemp.forEach(v => {
				combiApplyPrd.push({ ...v })
			})

			// 신규 주문
			const combiApplyPrdTemp = this.$store.getters['pay/getCombiApplyPrd']
			combiApplyPrdTemp.forEach(v => {
				combiApplyPrd.push({ ...v })
			})

			const combiPrdApplyExisting = []
			const combiPrdApplyNew = []
			const combiPrdOld = []
			const temp = []

			// 결합요금제 데이터를 분리
			await combiApplyPrd.forEach(v => {
				if (v.type === 'existing' && v.combiYn === 'N') {
					temp.push({ ...v })
				} else if (v.type === 'existing' && v.combiYn === 'Y') {
					combiPrdApplyExisting.push({ ...v })
				} else if (v.type === 'new' && v.combiYn === 'Y') {
					combiPrdApplyNew.push({ ...v })
				} else {
					combiPrdOld.push({ ...v })
				}
			})

			// 결합요금제 시작 COMBI_SEQ
			let count = parseFloat(this.productSaleInfo.E_OUTPUT.COMBI_SEQ)
			const startCombiSeq = parseFloat(this.productSaleInfo.E_OUTPUT.COMBI_SEQ)
			combiApplyPrd = []

			// IT_INPUT 생성 --------------------------------------------------------------
			if (combiPrdApplyNew.length !== 0) {
				await combiPrdApplyNew.forEach(v => {
					if (v.combiActive === true && v.COMBI_GB === 'O') {
						this.$log('PRD 1', v)
						combiApplyPrd.push({
							...v,
							COMBI_SEQ: this.$options.filters.numberPadding(startCombiSeq, 3)
						})
					} else if (v.combiActive === true && v.COMBI_GB === 'N') {
						this.$log(
							'COMBI_SEQ 1',
							this.$options.filters.numberPadding(count, 3)
						)
						this.$log('PRD 1', v)
						combiApplyPrd.push({
							...v,
							COMBI_SEQ: this.$options.filters.numberPadding(count, 3)
						})
						count += 1
					} else {
						combiApplyPrd.push({
							...v
						})
					}
				})
			}

			// 스토어에 저장된 상품 정보
			const productsTemp = this.$store.getters['pay/payProducts']
			// 상품정보 갱신

			let lastFestPackageId = ''
			await combiApplyPrd.forEach(v => {
				const prdIdx = parseFloat(v.I_SEQ) - 1
				productsTemp[prdIdx].payOptions.ET_INPUT = v
				if (v.type === 'new' && !v.combiActive) {
					productsTemp[prdIdx].payOptions.ET_INPUT.COMBI_GB = ''
					productsTemp[prdIdx].payOptions.ET_INPUT.COMBI_SEQ = ''
					productsTemp[prdIdx].payOptions.ET_INPUT.COMBI_NO = ''
					productsTemp[prdIdx].payOptions.ET_INPUT.DC_RATE = '0.00'
				}

				if (
					v.type === 'new' &&
					!v.packActive &&
					!v.festaActive &&
					!v.fifteenActive
				) {
					productsTemp[prdIdx].payOptions.ET_INPUT.PACKAGE_ID = ''
					productsTemp[prdIdx].payOptions.ET_INPUT.PACKAGE_RATE = ''
					productsTemp[prdIdx].payOptions.ET_INPUT.PACKAGE_SEQ = ''
				}

				if (v.type === 'new' && (v.festaActive || v.fifteenActive)) {
					lastFestPackageId =
						productsTemp[prdIdx].payOptions.ET_INPUT.PACKAGE_ID
				}
			})

			const packActivePrd = combiApplyPrd.filter(
				v => v.type === 'new' && v.packActive
			)
			// IT_INPUT 생성
			let combiApplyCount = 0
			let idx = 0
			for (const v of productsTemp) {
				idx++
				if (v.payOptions.ET_INPUT.DC_RATE !== '0.00') {
					combiApplyCount += 1
				}

				const data = v.payOptions.ET_INPUT
				// 페스타 패키지 적용되어 있는 상품이 있을 경우, 동시구매 단건 페스타 패키지(할인율 15%미만)
				// 단건에 묶을 수 있다면 페스타 패키지 ID로 변경하여 전송
				let packId = data.PACKAGE_ID
				let failMsg = ''
				if (
					lastFestPackageId.length > 0 &&
					packActivePrd.length === 1 &&
					data.PACKAGE_ID === '047'
				) {
					if (
						v.payOptions.ET_INPUT2.some(
							v =>
								v.PACKAGE_ID === lastFestPackageId &&
								Number(v.PACKAGE_RATE) < 15
						)
					) {
						packId = lastFestPackageId
					} else if (
						lastFestPackageId === '050' &&
						!['A141', 'A317', 'A339', '', undefined].includes(
							v.payOptions.promotion
						)
					) {
						packId = lastFestPackageId
					} else if (
						lastFestPackageId === '050' &&
						['A141', 'A317', 'A339', '', undefined].includes(
							v.payOptions.promotion
						)
					) {
						failMsg = `주문${idx}에서 A141(약정할인), A317(홈체험 유상), A339(홈체험 무상) 이외의 프로모션을 선택 후 진행해 주세요`
					}
				}

				if (failMsg.length > 0) {
					return {
						E_RETURN: {
							TYPE: 'F',
							MESSAGE: failMsg
						}
					}
				}

				const itInputParam = {
					I_SEQ: data.I_SEQ,
					CONTRACT_ID: data.CONTRACT_ID,
					SALE_ID: data.SALE_ID,
					GOODS_CD: data.GOODS_CD,
					USE_TP: data.USE_TP,
					REGI_ID: data.REGI_ID,
					REGI_GRADE: data.REGI_GRADE,
					FEE_START_NTH: data.FEE_START_NTH,
					RENTAL_YEARS: data.RENTAL_YEARS,
					LESGB: data.LESGB,
					USE_DUTY_MONTH: data.USE_DUTY_MONTH,
					ONCE_GRADE1: data.ONCE_GRADE1,
					ONCE_GRADE2: data.ONCE_GRADE2,
					ONCE_GRADE3: data.ONCE_GRADE3,
					PRICE: data.PRICE,
					MONTH_AMT: data.MONTH_AMT,
					PRE_ORDER_NO: data.PRE_ORDER_NO,
					COMBI_NO: data.COMBI_NO,
					COMBI_SEQ: data.COMBI_SEQ,
					COMBI_GB: data.COMBI_GB,
					DC_RATE: data.DC_RATE,
					DC_AMT: data.DC_AMT,
					PACKAGE_ID: packId,
					MPRM_CODE: v.payOptions.returnPromotionApplied
						? 'A067'
						: v.payOptions.promotion,
					MEM_CHK: data.MEM_CHK,
					PACKAGE_SEQ: data.PACKAGE_SEQ,
					PACKAGE_RATE: data.PACKAGE_RATE,
					HB_YN: data.HB_YN,
					INS_MONTH:
						data.sellType === 'Z004' ? v.payOptions.installmentMonth : '', //자체할부 여부에 따른 할부개월수
					ACPT_PAY: data.sellType === 'Z004' ? v.payOptions.acptAmt : '', //자체할부 여부에 따른 계약금
					HOME2_ORDER_NO: data.HOME2_ORDER_NO
				}

				if ('A350' === v.payOptions.promotion) {
					let memRes = await preMem(this.$store.getters['verify/mobOrderNo'], {
						I_CONTRACT_CUST_ID: this.$store.getters['verify/custNo'],
						I_PRM_CODE: v.payOptions.promotion
					})
					const et1843 = memRes.resultObject.data.ET_1843.filter(
						v => v.STYPE === 'Y'
					)
					if (et1843.length > 0) {
						Object.assign(itInputParam, {
							OTHER_ORD_NO: et1843[0].ORDER_NO || ''
						})
					}
				}

				IT_INPUT.push(itInputParam)
			}
			// IT_INPUT 생성 --------------------------------------------------------------

			// IT_COMBILIST_01 생성 -------------------------------------------------------
			// COMBILIST_01, IT_INPUT중에 비할인 계정 1개는 필수이고, 계정을 비할인 계정 처리
			if (combiPrdApplyExisting.length !== 0) {
				// 할인율로 정렬
				const combiPrdApplyExistingTemp = this.sortDcRate(combiPrdApplyExisting)
				combiPrdApplyExistingTemp.forEach(v => {
					// 결합 적용 하는 경우
					if (v.combiActive === true && v.COMBI_GB === 'O') {
						this.$log('PRD 1', v)
						combiApplyPrd.push({
							...v,
							COMBI_SEQ: this.$options.filters.numberPadding(startCombiSeq, 3),
							COMBI_GB: 'O'
						})
					} else if (v.combiActive === true && v.COMBI_GB === 'N') {
						this.$log(
							'COMBI_SEQ 1',
							this.$options.filters.numberPadding(count, 3)
						)
						this.$log('PRD 1', v)
						combiApplyPrd.push({
							...v,
							COMBI_SEQ: this.$options.filters.numberPadding(count, 3),
							COMBI_GB: 'N'
						})
						count += 1
					}
				})
			}

			// IT_COMBLIST_01 생성
			// 적용된 결합이 없을 경우 COMBLIST_01 초기화
			if (combiApplyCount !== 0) {
				combiApplyPrd.forEach(v => {
					if (v.type === 'existing') {
						IT_COMBLIST_01.push({
							ORDER_NO: v.ORDER_NO,
							COMBI_NO: v.COMBI_NO,
							COMBI_SEQ: v.COMBI_SEQ,
							COMBI_GB: v.COMBI_GB,
							DC_RATE: v.DC_RATE
						})
					}
				})
			}
			// -----------------------------------------------------------------------

			// IT_COMBIPACK 생성 -------------------------------------------------------
			// 주문번호 기준으로 병합
			const itPackCombi = []
			if (IT_COMBLIST_01.length !== 0) {
				IT_COMBLIST_01.forEach(v => {
					const packData = this.getItPackData(IT_PACKLIST_01, v.ORDER_NO)

					if (packData.length !== 0) {
						itPackCombi.push({
							...v,
							...temp
						})
					} else {
						itPackCombi.push({
							...v,
							DC_AMT: '',
							PACKAGE_ID: '',
							PACKAGE_RATE: ''
						})
					}
				})

				IT_PACKLIST_01.forEach(v => {
					const packData = this.getItPackData(IT_COMBLIST_01, v.ORDER_NO)

					if (packData.length === 0) {
						itPackCombi.push({
							...v,
							COMBI_SEQ: '',
							COMBI_NO: '',
							COMBI_GB: ''
						})
					}
				})
			} else {
				IT_PACKLIST_01.forEach(v => {
					itPackCombi.push({
						...v,
						COMBI_SEQ: '',
						COMBI_NO: '',
						COMBI_GB: ''
					})
				})
			}

			this.$log('IT_PACKCOMBI', itPackCombi)
			// IT_COMBIPACK 스토어에 저장
			this.$store.dispatch('pay/saveItPackcombi', itPackCombi)
			// --------------------------------------------------------------------------

			const res = await policyCheck({
				I_CONTRACT_CUST_ID: this.$store.getters['verify/custNo'],
				I_REGER_NO: this.$store.getters['common/codyNo'],
				IT_INPUT,
				IT_PACKLIST_01,
				IT_COMBLIST_01
			})

			return res.resultObject.data
		},

		// DC_RATE 기준 정렬
		sortDcRate(p) {
			const applyPrd = p
			applyPrd.sort((a, b) => {
				if (parseFloat(a.DC_RATE) > parseFloat(b.DC_RATE)) return 1
				if (parseFloat(a.DC_RATE) < parseFloat(b.DC_RATE)) return -1
				return 0
			})
			return applyPrd
		},

		// 상품정보, 할인정보 추출
		async dcInfo() {
			this.products.forEach(v => {
				// 자동 할인적용 버튼시 초기화
				v.saleInfo.saleAmt = []
				v.saleInfo.prdAmt = []

				/**
				 * 렌탈 상품정보, 할인정보 추출
				 */
				if (this.isRental(v)) {
					if (
						v.payOptions.prepayMonth === undefined ||
						v.payOptions.prepayMonth === '01'
					) {
						const rentalPrice = v.esProd.MONTH_AMT
						v.saleInfo = {
							...v.saleInfo,
							prdAmt: [
								...v.saleInfo.prdAmt,
								{
									name: '렌탈료',
									recvAmt: '',
									monthAmt: Number(rentalPrice),
									type: 'number',
									test: '1'
								}
							]
						}
					} else {
						const rentalPrice = v.esProd.MONTH_AMT
						const data = v.payOptions.T_PRE_AMT.find(
							x => x.PRE_MONTH === v.payOptions.prepayMonth
						)

						if (data !== undefined) {
							v.saleInfo = {
								...v.saleInfo,
								prdAmt: [
									...v.saleInfo.prdAmt,
									{
										name: `${data.PRE_MONTH / 12}년 후 렌탈료`,
										recvAmt: '',
										monthAmt: rentalPrice,
										type: 'number',
										test: '2'
									}
								]
							}
						}
					}

					// 렌탈등록비
					if (
						v.payOptions.orderType === 'P' ||
						v.payOptions.orderType === 'PK' ||
						v.payOptions.orderType === 'FP' ||
						v.payOptions.orderType === 'FT'
					) {
						// PRE-PASS
						const rentalRegPrice = v.esProd.REG_PRICE
						v.saleInfo = {
							...v.saleInfo,
							prdAmt: [
								...v.saleInfo.prdAmt,
								{
									name: '렌탈 등록비',
									recvAmt: Number(rentalRegPrice),
									monthAmt: '',
									type: 'number',
									test: '3'
								}
							]
						}
					} else {
						if (
							v.payOptions.regFeePayMethod &&
							v.payOptions.regFeePayMethod === 'A'
						) {
							// 일반
							const rentalRegPrice = v.esProd.REG_PRICE
							v.saleInfo = {
								...v.saleInfo,
								prdAmt: [
									...v.saleInfo.prdAmt,
									{
										name: '렌탈 등록비',
										recvAmt: Number(rentalRegPrice),
										monthAmt: '',
										type: 'number',
										test: '4'
									}
								]
							}
						}
					}

					// 부가서비스
					if (v.options.prdOption1 && v.options.prdOption1.code !== '') {
						const prdOption1Price = v.options.prdOption1.SVAMT
						v.saleInfo = {
							...v.saleInfo,
							prdAmt: [
								...v.saleInfo.prdAmt,
								{
									name: '부가서비스',
									recvAmt: '',
									monthAmt: Number(prdOption1Price),
									type: 'number',
									test: '6'
								}
							]
						}
					}

					// 선납금
					if (v.payOptions.prepayMonth && v.payOptions.prepayMonth !== '01') {
						const tPreAmt = v.payOptions.T_PRE_AMT.find(
							x => x.PRE_MONTH === v.payOptions.prepayMonth
						)

						v.saleInfo = {
							...v.saleInfo,
							prdAmt: [
								...v.saleInfo.prdAmt,
								{
									name: `${Number(v.payOptions.prepayMonth) / 12}년치 렌탈료`,
									recvAmt: Number(tPreAmt.MON_FI_AMT * tPreAmt.PRE_MONTH2),
									monthAmt: '',
									type: 'number',
									test: '7'
								}
							]
						}
					}

					// 선납할인2
					if (
						v.payOptions.downRentalPrepayAmt &&
						v.payOptions.downRentalPrepayAmt !== '0'
					) {
						const dPreAmt = v.payOptions.ET_ZSDS6087.filter(
							x => x.PRE_AMT === v.payOptions.downRentalPrepayAmt
						)[0]
						v.saleInfo = {
							...v.saleInfo,
							prdAmt: [
								...v.saleInfo.prdAmt,
								{
									name: '선납할인2 선납',
									recvAmt: dPreAmt.PRE_AMT,
									monthAmt: '',
									type: 'number',
									test: '37'
								}
							]
						}
					}

					// PRE-PASS
					if (v.payOptions.orderType === 'P') {
						v.saleInfo = {
							...v.saleInfo,
							saleAmt: [
								...v.saleInfo.saleAmt,
								{
									name: 'PRE-PASS',
									recvAmt: 100000,
									monthAmt: '',
									type: 'number',
									test: '8'
								}
							]
						}
					}

					// 동시구매 패키지
					if (
						v.payOptions.orderType === 'PK' &&
						['Z001', 'Z003'].includes(v.esProd.ORD_TYPE)
					) {
						v.saleInfo = {
							...v.saleInfo,
							saleAmt: [
								...v.saleInfo.saleAmt,
								{
									name: 'PRE-PASS 적용 할인',
									recvAmt: 100000,
									monthAmt: '',
									type: 'number',
									test: '9'
								}
							]
						}
					}

					// 페스타 or 코웨이 패키지 할인
					if (
						['FP', 'FT'].includes(v.payOptions.orderType) &&
						['Z001'].includes(v.esProd.ORD_TYPE)
					) {
						v.saleInfo = {
							...v.saleInfo,
							saleAmt: [
								...v.saleInfo.saleAmt,
								{
									name: 'PRE-PASS 적용 할인',
									recvAmt: 100000,
									monthAmt: '',
									type: 'number',
									test: '9'
								}
							]
						}
					}

					//프로모션
					if (
						v.payOptions.promotion &&
						v.payOptions.promotion !== '' &&
						v.payOptions.returnPromotionApplied === false &&
						v.payOptions.ET_ZSDS5204.length !== 0
					) {
						if (Number(v.payOptions.promotionDcAmt) === 0) {
							// 금액이 0인 경우 '별도 혜택'
							v.saleInfo = {
								...v.saleInfo,
								saleAmt: [
									...v.saleInfo.saleAmt,
									{
										name: v.payOptions.promotionName,
										recvAmt: '별도 혜택',
										monthAmt: '별도 혜택',
										type: 'string'
									}
								]
							}
						} else {
							v.saleInfo = {
								...v.saleInfo,
								saleAmt: [
									...v.saleInfo.saleAmt,
									{
										name: v.payOptions.promotionName,
										recvAmt: '',
										monthAmt: Number(v.payOptions.promotionDcAmt),
										type: 'number',
										test: '11'
									}
								]
							}
						}
					} else if (v.payOptions.returnPromotionApplied === false) {
						// 그외, 프로모션 선택 없음
						v.saleInfo = {
							...v.saleInfo,
							saleAmt: [
								...v.saleInfo.saleAmt,
								{
									name: '프로모션',
									recvAmt: 0,
									monthAmt: 0,
									type: 'number',
									test: '12'
								}
							]
						}
					}

					// 미반납 재랜탈
					if (v.payOptions.returnPromotionApplied === true) {
						v.saleInfo = {
							...v.saleInfo,
							saleAmt: [
								...v.saleInfo.saleAmt,
								{
									name: '미반납 재렌탈 프로모션',
									recvAmt: '별도 혜택',
									monthAmt: '별도 혜택',
									type: 'string'
								}
							]
						}
					}

					// 정기결제할인: 렌탈, 재랜탈만 해당
					v.saleInfo = {
						...v.saleInfo,
						saleAmt: [
							...v.saleInfo.saleAmt,
							{
								name: '정기결제할인',
								recvAmt: '',
								monthAmt: 1000,
								type: 'number',
								test: '13'
							}
						]
					}

					// 렌탈료 선납 할인
					if (v.payOptions.prepayMonth) {
						if (v.payOptions.prepayMonth !== '01') {
							v.saleInfo = {
								...v.saleInfo,
								saleAmt: [
									...v.saleInfo.saleAmt,
									{
										name: '렌탈료 선납 할인',
										recvAmt: Number(this.calcPrepayDc(v)),
										monthAmt: '',
										type: 'number',
										test: '14'
									}
								]
							}
						}
					}
				} else if (
					v.options.sellType.code === 'Z003' ||
					v.options.sellType.code === 'Z005'
				) {
					// 일시불, 일시불 보상 판매
					v.saleInfo = {
						...v.saleInfo,
						prdAmt: [
							...v.saleInfo.prdAmt,
							{
								name: '일시불',
								recvAmt: Number(v.esProd.PRICE),
								monthAmt: '',
								type: 'number',
								test: '15'
							}
						]
					}

					// 멤버십
					if (
						v.options.membershipType &&
						v.options.membershipType.code !== '' &&
						v.payOptions.promotion !== 'A127'
					) {
						// 멤버십 가입
						if (
							v.options.membershipType.code !== '' &&
							v.payOptions.membership !== '' &&
							v.payOptions.membership !== '00' &&
							v.payOptions.msDc === '00'
						) {
							v.saleInfo = {
								...v.saleInfo,
								prdAmt: [
									...v.saleInfo.prdAmt,
									{
										name: '멤버십',
										recvAmt: '',
										monthAmt: Number(v.options.membershipType.MON_AMT),
										type: 'number',
										test: '16'
									}
								]
							}
						} else if (
							v.payOptions.membership !== '00' &&
							v.payOptions.msDc === '01'
						) {
							v.saleInfo = {
								...v.saleInfo,
								prdAmt: [
									...v.saleInfo.prdAmt,
									{
										name: '멤버십',
										recvAmt: Number(v.options.membershipType.MON_AMT * 12),
										monthAmt: '',
										type: 'number',
										test: '17'
									}
								]
							}

							// 선납 할인
							v.saleInfo = {
								...v.saleInfo,
								saleAmt: [
									...v.saleInfo.saleAmt,
									{
										name: '멤버십 선납할인',
										recvAmt: Number(v.payOptions.msAmtDc),
										monthAmt: '',
										type: 'number',
										test: '18'
									}
								]
							}
						}
					}

					// 프로모션
					if (
						v.payOptions.promotion &&
						v.payOptions.promotion !== '' &&
						v.esProd.ORD_TYPE !== 'Z005'
					) {
						if (Number(v.payOptions.promotionDcAmt) === 0) {
							// 프로모션 금액이 없는 경우 '별도 혜택'
							v.saleInfo = {
								...v.saleInfo,
								saleAmt: [
									...v.saleInfo.saleAmt,
									{
										name: v.payOptions.promotionName,
										recvAmt: '별도 혜택',
										monthAmt: '별도 혜택',
										type: 'string'
									}
								]
							}
						} else {
							v.saleInfo = {
								...v.saleInfo,
								saleAmt: [
									...v.saleInfo.saleAmt,
									{
										name: v.payOptions.promotionName,
										recvAmt: Number(v.payOptions.promotionDcAmt),
										monthAmt: '',
										type: 'number',
										test: '19'
									}
								]
							}
						}
					} else {
						// 그외, 프로모션 선택 없음
						v.saleInfo = {
							...v.saleInfo,
							saleAmt: [
								...v.saleInfo.saleAmt,
								{
									name: '프로모션',
									recvAmt: 0,
									monthAmt: 0,
									type: 'number',
									test: '20'
								}
							]
						}
					}
				} else if (v.options.sellType.code === 'Z004') {
					// 자체할부
					// 계약금
					if (v.payOptions.acptAmt) {
						v.saleInfo = {
							...v.saleInfo,
							prdAmt: [
								...v.saleInfo.prdAmt,
								{
									name: '계약금',
									recvAmt: Number(v.payOptions.acptAmt),
									monthAmt: '',
									type: 'number',
									test: '2'
								}
							]
						}
					}

					let insMonthAmt = 0
					// 할부금
					if (v.payOptions.installmentMonth) {
						insMonthAmt =
							(Number(v.esProd.PRICE) - Number(v.payOptions.acptAmt)) /
							Number(v.payOptions.installmentMonth)

						let dispInsMonthAmt = Number(insMonthAmt).toFixed(0)
						if (
							v.payOptions.ET_ZSDS1518 &&
							v.payOptions.ET_ZSDS1518.length > 0
						) {
							if (Number(v.payOptions.ET_ZSDS1518[0].DC_AMT) > 0) {
								dispInsMonthAmt = Math.floor(insMonthAmt).toFixed(0)
							}
						}

						v.saleInfo = {
							...v.saleInfo,
							prdAmt: [
								...v.saleInfo.prdAmt,
								{
									name: '할부금',
									recvAmt: '',
									monthAmt: dispInsMonthAmt,
									type: 'number',
									test: '23'
								}
							]
						}
					}

					// 자체할부 상품 할인 https://jira.nmn.io/browse/CWMJPRO-648
					if (
						v.payOptions.ET_ZSDS1518 &&
						v.payOptions.ET_ZSDS1518[0] &&
						v.payOptions.ET_ZSDS1518[0].DC_AMT &&
						v.payOptions.installmentMonth
					) {
						const intraCalcInsMonthAmt =
							(Number(v.esProd.PRICE) -
								Number(v.payOptions.acptAmt) -
								Number(v.payOptions.ET_ZSDS1518[0].DC_AMT)) /
							Number(v.payOptions.installmentMonth)

						let dcAmtInsMonthAmt =
							Math.floor(insMonthAmt) - Math.round(intraCalcInsMonthAmt)

						if (dcAmtInsMonthAmt < 0) dcAmtInsMonthAmt = 0

						v.saleInfo = {
							...v.saleInfo,
							saleAmt: [
								...v.saleInfo.saleAmt,
								{
									name: '일시불 할인',
									recvAmt: '',
									monthAmt: Math.floor(dcAmtInsMonthAmt).toFixed(0),
									type: 'number',
									test: '34'
								}
							]
						}
					}

					// 멤버십
					if (
						v.options.membershipType &&
						v.options.membershipType.code !== '' &&
						v.payOptions.promotion !== 'A127'
					) {
						// 멤버십 가입
						if (
							v.options.membershipType.code !== '' &&
							v.payOptions.membership !== '' &&
							v.payOptions.msDc === '00'
						) {
							v.saleInfo = {
								...v.saleInfo,
								prdAmt: [
									...v.saleInfo.prdAmt,
									{
										name: '멤버십',
										recvAmt: '',
										monthAmt: Number(v.options.membershipType.MON_AMT),
										type: 'number',
										test: '24'
									}
								]
							}
						} else if (
							v.payOptions.membership === '01' &&
							v.payOptions.msDc === '01'
						) {
							v.saleInfo = {
								...v.saleInfo,
								prdAmt: [
									...v.saleInfo.prdAmt,
									{
										name: '멤버십',
										recvAmt: Number(v.options.membershipType.MON_AMT * 12),
										monthAmt: '',
										type: 'number',
										test: '25'
									}
								]
							}

							// 선납 할인
							v.saleInfo = {
								...v.saleInfo,
								saleAmt: [
									...v.saleInfo.saleAmt,
									{
										name: '멤버십 선납할인',
										recvAmt: Number(v.payOptions.msAmtDc),
										monthAmt: '',
										type: 'number',
										test: '26'
									}
								]
							}
						}
					}
					// 프로모션
					if (v.payOptions.promotion && v.payOptions.promotion !== '') {
						if (Number(v.payOptions.promotionDcAmt) === 0) {
							// 프로모션 금액이 없는 경우 '별도혜택'
							v.saleInfo = {
								...v.saleInfo,
								saleAmt: [
									...v.saleInfo.saleAmt,
									{
										name: v.payOptions.promotionName,
										recvAmt: '별도 혜택',
										monthAmt: '별도 혜택',
										type: 'string'
									}
								]
							}
						} else {
							v.saleInfo = {
								...v.saleInfo,
								saleAmt: [
									...v.saleInfo.saleAmt,
									{
										name: v.payOptions.promotionName,
										recvAmt: '',
										monthAmt: Number(v.payOptions.promotionDcAmt),
										type: 'number',
										test: '27'
									}
								]
							}
						}
					} else {
						// 그외, 프로모션 선택 없음
						v.saleInfo = {
							...v.saleInfo,
							saleAmt: [
								...v.saleInfo.saleAmt,
								{
									name: '프로모션',
									recvAmt: 0,
									monthAmt: 0,
									type: 'number',
									test: '28'
								}
							]
						}
					}
				}

				// 별매상품
				if (v.options.prdOption2) {
					let prdOption2Price = 0
					if (v.options.prdOption2.CONTRACT_ID === '1') {
						// 수납
						const item = v.options.prdOption2
						prdOption2Price =
							item.CONTRACT_ID === '1'
								? item.GOODS_AMT === '0'
									? item.PRICE
									: item.GOODS_AMT
								: item.MONTH_AMT

						v.saleInfo = {
							...v.saleInfo,
							prdAmt: [
								...v.saleInfo.prdAmt,
								{
									name: '별매상품',
									recvAmt: Number(prdOption2Price),
									monthAmt: '',
									type: 'number',
									test: '29'
								}
							]
						}
					} else if (v.options.prdOption2.CONTRACT_ID === '2') {
						// 렌탈
						prdOption2Price = v.options.prdOption2.MONTH_AMT
						v.saleInfo = {
							...v.saleInfo,
							prdAmt: [
								...v.saleInfo.prdAmt,
								{
									name: '별매상품',
									recvAmt: '',
									monthAmt: Number(prdOption2Price),
									type: 'number',
									test: '30'
								}
							]
						}
					}
				}

				/**
				 * 동시구매 할인
				 * 원단위 절상한다.
				 * [{월렌탈료 - (자동이체할인 + 프로모션할인)} * 할인율] 1원 단위 절상 + [(별매상품 * 할인율)] 1원 단위 절상 = 동시구매 할인금액
				 */
				let packAmt = 0

				if (
					getPackCheck(v.esProd.ORD_TYPE) &&
					!getPackPrdhaCheck(v.esProd.GOODS_CD)
				) {
					if (v.payOptions.packActive && this.isRental(v)) {
						// 상품금액
						let prdAmt = Number(v.payOptions.ET_INPUT.MONTH_AMT)

						// 별매상품
						let prdOpt2Amt = 0
						if (
							v.options.prdOption2 &&
							v.options.prdOption2.code !== '' &&
							(v.options.prdOption2.LESGB === 'L' ||
								v.options.prdOption2.LESGB === 'R')
						) {
							prdOpt2Amt = Number(v.options.prdOption2.MONTH_AMT)
						}

						// 정기결제 1천원 할인
						const regularPayment = 1000

						// 프로모션 할인 요금
						let promotionAmt = 0
						if (
							v.payOptions.promotionDcAmt &&
							v.payOptions.promotionDcAmt !== ''
						) {
							promotionAmt = Number(v.payOptions.promotionDcAmt)
						}

						// 할인된 월 렌탈료
						let monthAmt = 0
						/**
						 * 등록비 분납이 아닌 경우
						 * 할인된 금액은 원단위 절상을 한다
						 */
						const prdAmtSum = prdAmt // 주상품
						const prdDcAmtSum = regularPayment + promotionAmt // 정기결제할인 + 프로모션 + 분납 등록비
						const prdDcResultTemp = Math.ceil(
							((prdAmtSum - prdDcAmtSum) * v.payOptions.ET_INPUT.PACKAGE_RATE) /
								100
						)
						const prdDcResult = Math.ceil(prdDcResultTemp / 10) * 10 // 원단위 절상

						const prdOpt2DcAmtTemp = Math.ceil(
							(prdOpt2Amt * v.payOptions.ET_INPUT.PACKAGE_RATE) / 100
						)
						const prdOpt2DcAmt = Math.ceil(prdOpt2DcAmtTemp / 10) * 10 // 원단위 절상

						monthAmt = prdDcResult + prdOpt2DcAmt

						packAmt = monthAmt
						this.$log('packAmt', packAmt)

						v.saleInfo = {
							...v.saleInfo,
							saleAmt: [
								...v.saleInfo.saleAmt,
								{
									name: '동시구매할인요금제',
									recvAmt: '',
									monthAmt: monthAmt,
									type: 'number',
									test: '31'
								}
							]
						}
					} else if (v.payOptions.packActive) {
						// 프로모션 할인 요금
						let promotionAmt = 0
						if (
							v.payOptions.promotionDcAmt &&
							v.payOptions.promotionDcAmt !== ''
						) {
							promotionAmt = v.payOptions.promotionDcAmt
						}

						// 상품금액
						let allPrdAmt = Number(v.payOptions.ET_INPUT.PRICE)
						let prdPrice = allPrdAmt - promotionAmt

						const prdDcAmt =
							(prdPrice * v.payOptions.ET_INPUT.PACKAGE_RATE) / 100

						v.saleInfo = {
							...v.saleInfo,
							saleAmt: [
								...v.saleInfo.saleAmt,
								{
									name: '동시구매할인요금제',
									recvAmt: Math.ceil(prdDcAmt / 10) * 10, // 원단위 절상
									monthAmt: '',
									type: 'number',
									test: '32'
								}
							]
						}
					}
				}

				// 페스타 패키지 할인 (렌탈만 가능)
				if (v.payOptions.festaActive && v.options.sellType.code === 'Z001') {
					// 상품금액
					let prdAmt = Number(v.payOptions.ET_INPUT.MONTH_AMT)

					// 별매상품
					let prdOpt2Amt = 0
					if (
						v.options.prdOption2 &&
						v.options.prdOption2.code !== '' &&
						(v.options.prdOption2.LESGB === 'L' ||
							v.options.prdOption2.LESGB === 'R')
					) {
						prdOpt2Amt = Number(v.options.prdOption2.MONTH_AMT)
					}

					// 정기결제 1천원 할인
					const regularPayment = 1000

					// 프로모션 할인 요금
					let promotionAmt = 0
					if (
						v.payOptions.promotionDcAmt &&
						v.payOptions.promotionDcAmt !== ''
					) {
						promotionAmt = Number(v.payOptions.promotionDcAmt)
					}

					// 할인된 월 렌탈료
					let monthAmt = 0
					/**
					 * 등록비 분납이 아닌 경우
					 * 할인된 금액은 원단위 절상을 한다
					 */
					const prdAmtSum = prdAmt // 주상품
					const prdDcAmtSum = regularPayment + promotionAmt // 정기결제할인 + 프로모션 + 분납 등록비
					const prdDcResultTemp = Math.ceil(
						((prdAmtSum - prdDcAmtSum) * v.payOptions.ET_INPUT.PACKAGE_RATE) /
							100
					)
					const prdDcResult = Math.ceil(prdDcResultTemp / 10) * 10 // 원단위 절상

					const prdOpt2DcAmtTemp = Math.ceil(
						(prdOpt2Amt * v.payOptions.ET_INPUT.PACKAGE_RATE) / 100
					)
					const prdOpt2DcAmt = Math.ceil(prdOpt2DcAmtTemp / 10) * 10 // 원단위 절상

					monthAmt = prdDcResult + prdOpt2DcAmt

					v.saleInfo = {
						...v.saleInfo,
						saleAmt: [
							...v.saleInfo.saleAmt,
							{
								name: '페스타 패키지 할인',
								recvAmt: '',
								monthAmt: monthAmt,
								type: 'number',
								test: '35'
							}
						]
					}
				}

				// 코웨이 패키지 할인 (렌탈만 가능)
				if (v.payOptions.fifteenActive && v.options.sellType.code === 'Z001') {
					// 상품금액
					let prdAmt = Number(v.payOptions.ET_INPUT.MONTH_AMT)

					// 별매상품
					let prdOpt2Amt = 0
					if (
						v.options.prdOption2 &&
						v.options.prdOption2.code !== '' &&
						(v.options.prdOption2.LESGB === 'L' ||
							v.options.prdOption2.LESGB === 'R')
					) {
						prdOpt2Amt = Number(v.options.prdOption2.MONTH_AMT)
					}

					// 정기결제 1천원 할인
					const regularPayment = 1000

					// 프로모션 할인 요금
					let promotionAmt = 0
					if (
						v.payOptions.promotionDcAmt &&
						v.payOptions.promotionDcAmt !== ''
					) {
						promotionAmt = Number(v.payOptions.promotionDcAmt)
					}

					// 할인된 월 렌탈료
					let monthAmt = 0
					/**
					 * 등록비 분납이 아닌 경우
					 * 할인된 금액은 원단위 절상을 한다
					 */
					const prdAmtSum = prdAmt // 주상품
					const prdDcAmtSum = regularPayment + promotionAmt // 정기결제할인 + 프로모션 + 분납 등록비
					const prdDcResultTemp = Math.ceil(
						((prdAmtSum - prdDcAmtSum) * v.payOptions.ET_INPUT.PACKAGE_RATE) /
							100
					)
					const prdDcResult = Math.ceil(prdDcResultTemp / 10) * 10 // 원단위 절상

					const prdOpt2DcAmtTemp = Math.ceil(
						(prdOpt2Amt * v.payOptions.ET_INPUT.PACKAGE_RATE) / 100
					)
					const prdOpt2DcAmt = Math.ceil(prdOpt2DcAmtTemp / 10) * 10 // 원단위 절상

					monthAmt = prdDcResult + prdOpt2DcAmt

					v.saleInfo = {
						...v.saleInfo,
						saleAmt: [
							...v.saleInfo.saleAmt,
							{
								name: '코웨이 패키지 할인',
								recvAmt: '',
								monthAmt: monthAmt,
								type: 'number',
								test: '37'
							}
						]
					}
				}
				/**
				 * 결합요금제
				 * 결합요금제는 렌탈, 재렌탈만 가능하다.
				 * 할인된 금액은 원단위 절상을 한다.
				 * {(월렌탈료 + 별매상품 + 부가서비스) - (정기결제할인 + 프로모션할인 + 동시구매할인 + 등록비분납(10000))} *  (할인율) 1원 단위 절상 = 결합 할인금액
				 */
				if (this.isRental(v)) {
					if (v.esProd.ORD_TYPE === 'Z001' || v.esProd.ORD_TYPE === 'Z002') {
						if (v.payOptions.combiActive) {
							// 상품금액
							let prdAmt = Number(v.payOptions.ET_INPUT.MONTH_AMT)

							// 부가서비스
							let prdOpt1Amt = 0
							if (v.options.prdOption1 && v.options.prdOption1.code !== '') {
								prdOpt1Amt = Number(v.options.prdOption1.SVAMT)
							}

							// 별매상품
							let prdOpt2Amt = 0
							if (
								v.options.prdOption2 &&
								v.options.prdOption2.code !== '' &&
								(v.options.prdOption2.LESGB === 'L' ||
									v.options.prdOption2.LESGB === 'R')
							) {
								prdOpt2Amt = Number(v.options.prdOption2.MONTH_AMT)
							}

							// 정기결제 1천원 할인
							const regularPayment = 1000

							// 프로모션 할인 요금
							let promotionAmt = 0
							if (
								v.payOptions.promotionDcAmt &&
								v.payOptions.promotionDcAmt !== ''
							) {
								promotionAmt = Number(v.payOptions.promotionDcAmt)
							}

							// 할인된 월 렌탈료
							let monthAmt = 0
							/**
							 * 등록비 분납이 아닌 경우
							 * 할인된 금액은 원단위 절상을 한다.
							 */
							const prdAmtSum = prdAmt + prdOpt1Amt + prdOpt2Amt // 주상품 + 부가서비스
							const prdDcAmtSum = regularPayment + promotionAmt + packAmt // 정기결제할인 + 프로모션 + 분납 등록비 + 동시구매 할인 금액
							const monthAmtTemp = Math.ceil(
								((prdAmtSum - prdDcAmtSum) * v.payOptions.ET_INPUT.DC_RATE) /
									100
							)
							monthAmt = Math.ceil(monthAmtTemp / 10) * 10

							v.saleInfo = {
								...v.saleInfo,
								saleAmt: [
									...v.saleInfo.saleAmt,
									{
										name: '결합요금제',
										recvAmt: '',
										monthAmt: monthAmt,
										type: 'number',
										test: '33'
									}
								]
							}
						}
					}
					// 선납할인2 할인
					if (v.payOptions.downRentalPrepayAmt) {
						if (v.payOptions.downRentalPrepayAmt !== '0') {
							v.saleInfo = {
								...v.saleInfo,
								saleAmt: [
									...v.saleInfo.saleAmt,
									{
										name: '선납할인2 할인',
										recvAmt: '',
										monthAmt: Number(this.calcDownRentalDc(v)),
										type: 'number',
										test: '35'
									}
								]
							}
						}
					}
					// 선납할인2 선납 차감
					if (v.payOptions.downRentalPrepayAmt) {
						if (v.payOptions.downRentalPrepayAmt !== '0') {
							const dPreAmt = v.payOptions.ET_ZSDS6087.filter(
								x => x.PRE_AMT === v.payOptions.downRentalPrepayAmt
							)[0]
							v.saleInfo = {
								...v.saleInfo,
								saleAmt: [
									...v.saleInfo.saleAmt,
									{
										name: '선납할인2 선납 차감',
										recvAmt: '',
										monthAmt: Number(
											dPreAmt.PRE_AMT / (dPreAmt.RET_YEARS * 12)
										).toFixed(0),
										type: 'number',
										test: '36'
									}
								]
							}
						}
					}
				}
			})
			this.$forceUpdate()
		},

		// 상품금액 수납 계산
		displayPrdRecvAmt(prdAmt) {
			let sum = 0
			let result = ''
			let count = 0

			prdAmt.forEach(v => {
				if (v.recvAmt !== '') {
					sum += Number(v.recvAmt)
					count += 1
				}
			})

			if (count === 0) {
				result = ''
				sum = ''
			} else {
				result = `수납 ${this.$options.filters.comma(sum)}원`
			}

			return { string: result, number: sum }
		},

		// 상품금액 월 금액 계산
		displayPrdMonthAmt(prdAmt) {
			let sum = 0
			let result = ''
			let count = 0

			prdAmt.forEach(v => {
				if (v.monthAmt !== '') {
					sum += Number(v.monthAmt)
					count += 1
				}
			})

			if (count === 0) {
				result = ''
				sum = ''
			} else {
				result = `${this.$options.filters.comma(sum)}원/월`
			}

			return { string: result, number: sum }
		},

		// 상품금액 월 금액 계산(선납 할인 계산시, 분납 등록비 제외)
		displayPrepayMonthAmt(p) {
			let sum = 0
			let result = ''
			let count = 0

			// 할인금액
			const saleMonthAmt = this.displaySaleMonthAmt(p.saleInfo.saleAmt).number
			const prdAmt = p.saleInfo.prdAmt
			prdAmt.forEach(v => {
				if (v.monthAmt !== '') {
					sum += Number(v.monthAmt)
					count += 1
				}
			})

			if (count === 0) {
				result = ''
				sum = ''
			} else {
				result = sum - saleMonthAmt
			}

			return result
		},

		// 할인금액 수납 금액 계산
		displaySaleRecvAmt(prdAmt) {
			let sum = 0
			let result = ''
			let numCount = 0
			let strCount = 0
			let value = ''

			prdAmt.forEach(v => {
				if (v.recvAmt !== '' && v.type === 'number') {
					sum += Number(v.recvAmt)
					numCount += 1
				} else if (v.recvAmt !== '' && v.type === 'string') {
					strCount += 1
					if (v.recvAmt === '별도 혜택') {
						value = '별도 혜택'
					}
				}
			})

			if (numCount === 0 && strCount === 0) {
				result = ''
				sum = ''
			} else if (numCount === 0 && strCount > 0) {
				result = value
				sum = ''
			} else if (numCount > 0 && sum === 0) {
				result = `0원`
			} else {
				result = `수납 -${this.$options.filters.comma(sum)}원`
			}

			return { string: result, number: sum }
		},

		// 할인금액 월 금액 계산
		displaySaleMonthAmt(saleAmt) {
			let sum = 0
			let result = ''
			let numCount = 0
			let strCount = 0
			let value = ''

			saleAmt.forEach(v => {
				if (v.monthAmt !== '' && v.type === 'number') {
					sum += Number(v.monthAmt)
					numCount += 1
				} else if (v.monthAmt !== '' && v.type === 'string') {
					strCount += 1
					if (v.monthAmt === '별도 혜택') {
						value = '별도 혜택'
					}
				}
			})

			if (numCount === 0 && strCount === 0) {
				result = ''
				sum = ''
			} else if (numCount === 0 && strCount > 0) {
				result = value
				sum = ''
			} else if (numCount > 0 && sum === 0) {
				result = `0원`
			} else {
				result = `-${this.$options.filters.comma(sum)}원/월`
			}

			return { string: result, number: sum }
		},

		// 상품별 총 금액 계산 (수납, 월 납입금)
		displayPrdTotalAmt(saleInfo) {
			let prdRecvTemp = this.displayPrdRecvAmt(saleInfo.prdAmt).number // 상품 수납금
			let prdMonthTemp = this.displayPrdMonthAmt(saleInfo.prdAmt).number // 상품 월 납입금
			let saleRecvTemp = this.displaySaleRecvAmt(saleInfo.saleAmt).number // 할인 수납급
			let saleMonthTotal = this.displaySaleMonthAmt(saleInfo.saleAmt).number // 할인 월 납입금

			// 상품 수납 총 금액 (수납금액 - 할인 수납금액)
			let prdRecvTotal = ''
			let prdRecvTotalNum = 0
			if (prdRecvTemp === '' && saleRecvTemp === '') {
				prdRecvTotal = ''
				prdRecvTotalNum = 0
			} else {
				prdRecvTotal = `${this.$options.filters.comma(
					prdRecvTemp - saleRecvTemp
				)}원`
				prdRecvTotalNum = prdRecvTemp - saleRecvTemp
			}

			// 상품 할인 총 금액 (월 금액 - 할인 월 금액)
			let prdMonthTotal = ''
			let prdMonthTotalNum = 0
			if (prdMonthTemp === '' && saleMonthTotal === '') {
				prdMonthTotal = ''
				prdMonthTotalNum = 0
			} else {
				prdMonthTotal = `${this.$options.filters.comma(
					prdMonthTemp - saleMonthTotal
				)}원/월`
				prdMonthTotalNum = prdMonthTemp - saleMonthTotal
			}

			return {
				prdRecvTotal: prdRecvTotal,
				prdRecvTotalNum: prdRecvTotalNum,
				prdMonthTotal: prdMonthTotal,
				prdMonthTotalNum: prdMonthTotalNum
			}
		},

		// 전체 상품 합계 계산
		allPrdTotalAmt() {
			let result = {
				prdRecvTotal: 0,
				prdMonthTotal: 0,
				saleRecvTotal: 0,
				saleMonthTotal: 0,
				recvTotal: 0,
				monthTotal: 0
			}

			// 합계 금액 계산
			this.products.forEach(v => {
				result.prdRecvTotal += Number(
					this.displayPrdRecvAmt(v.saleInfo.prdAmt).number
				)
				result.prdMonthTotal += Number(
					this.displayPrdMonthAmt(v.saleInfo.prdAmt).number
				)
				result.saleRecvTotal += Number(
					this.displaySaleRecvAmt(v.saleInfo.saleAmt).number
				)
				result.saleMonthTotal += Number(
					this.displaySaleMonthAmt(v.saleInfo.saleAmt).number
				)
			})

			// 수납 전체 합계 금액
			result.recvTotal += result.prdRecvTotal - result.saleRecvTotal
			// 월 전체 합계 금액
			result.monthTotal += result.prdMonthTotal - result.saleMonthTotal

			return result
		},

		// 할인 요금제 적용 - 변경한 할인 요금제 반영
		combiApplyProducts(combiData) {
			const COMBI_DATA = combiData

			const combiApplyNewPrd = []
			const combiApplyExistingPrd = []

			// 적용한 상품
			// 스토어에 초기화 또는 저장
			COMBI_DATA.combiExistingPrd.forEach(v => {
				if (v.combiActive) {
					combiApplyExistingPrd.push({ ...v })
				}
			})

			COMBI_DATA.combiApplyPrd.forEach(v => {
				if (v.type === 'new') {
					combiApplyNewPrd.push({ ...v })
				} else if (v.type === 'existing') {
					combiApplyExistingPrd.push({ ...v })
				}
			})
			this.$store.dispatch('pay/saveApplyPrdAll', combiApplyNewPrd)
			this.$store.dispatch('pay/saveCombiExistingPrd', combiApplyExistingPrd)

			COMBI_DATA.combiApplyPrd.forEach(v => {
				// 적용신청 된 신규 주문 정보 갱신
				if (v.type === 'new') {
					const prdIdx = parseFloat(v.I_SEQ) - 1
					this.products[prdIdx].payOptions.combiActive = v.combiActive
					this.products[prdIdx].payOptions.ET_INPUT.COMBI_GB = v.COMBI_GB
					this.products[prdIdx].payOptions.ET_INPUT.COMBI_NO = v.COMBI_NO
					this.products[prdIdx].payOptions.ET_INPUT.COMBI_SEQ = v.COMBI_SEQ
					this.products[prdIdx].payOptions.ET_INPUT.DC_AMT = v.DC_AMT
					this.products[prdIdx].payOptions.ET_INPUT.DC_RATE = v.DC_RATE
					this.handleFecthPayOption(prdIdx)
				}
			})

			this.dcInfo()
		},

		packApplyProducts(packData) {
			const PACK_DATA = packData
			const packApplyNewPrd = []
			const packApplyExistingPrd = []

			// 적용신청에서 결합 가능한 상품
			PACK_DATA.packApplyPrd.forEach(v => {
				if (v.type === 'new') {
					packApplyNewPrd.push({ ...v })
				} else if (v.type === 'existing') {
					packApplyExistingPrd.push({ ...v })
				}
			})
			this.$store.dispatch('pay/saveApplyPrdAll', packApplyNewPrd)
			this.$store.dispatch('pay/savePackagePrd', packApplyExistingPrd)

			this.packDataApplyPrd(PACK_DATA, 'pack')
			this.dcInfo()
		},

		// 별매상품 정보
		displayPrdOption2Item(item) {
			if (!item.code) return { name: '추가 안함' }
			const contract = item.CONTRACT_ID === '1' ? '일시불' : '렌탈'
			const price =
				item.CONTRACT_ID === '1'
					? item.GOODS_AMT === '0'
						? item.PRICE
						: item.GOODS_AMT
					: item.MONTH_AMT
			const div = item.CONTRACT_ID === '1' ? '+' : '월 +'
			const optionItem = {
				contract: `[${contract}]`,
				price: `${div} ${this.$options.filters.comma(price)}원`,
				name: item.text
			}
			return optionItem
		},

		// 자체할부 최소 계약금
		async minInsAmt(P) {
			if (P.options.sellType.code === 'Z004') {
				// 금액 10%
				const minInsAmt = Number(P.payOptions.ET_ZSDS1518[0].ACPT_PAY_MIN)
				let min = minInsAmt

				// 천원 이하 절삭
				const minInsAmtRest = minInsAmt % 1000
				if (minInsAmtRest !== 0) {
					min = minInsAmt - minInsAmtRest
				}

				this.$set(P.valueOptions, 'min', min)
				if (!P.payOptions.acptAmt || P.payOptions.acptAmt < min) {
					const temp = min.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
					this.$set(P.payOptions, 'displayAcptAmt', temp)
				}

				setTimeout(() => {
					this.$set(P.payOptions, 'acptAmt', min)
				}, 300)
			}
		},

		// 금액계산 펼침/닫힘
		onClickPriceCalculation(param) {
			if (this.calcPriceActive !== param) {
				this.calcPriceActive = param
			} else {
				this.calcPriceActive = null
			}
		},

		// 프리패스 (Pre-Pass) 체크
		checkPrePass() {
			// ''
			// '
			let monthPrePassCnt = Number(this.productSaleInfo.E_OUTPUT.PASS_CNT)
			let selectPrePassCnt = 0

			this.products.forEach(v => {
				if (v.payOptions.orderType === 'P') {
					selectPrePassCnt += 1
				}
			})

			if (monthPrePassCnt === 0 && selectPrePassCnt !== 0) {
				this.$alert({
					message: `PRE-PASS는 당월 최대 9개까지만 적용 가능합니다. 기존 9개 적용되어 더 이상 적용할 수 없습니다.`
				})
				return false
			} else {
				if (selectPrePassCnt > monthPrePassCnt) {
					this.$alert({
						message: `PRE-PASS는 당월 최대 9개 까지만 적용 가능합니다. 기존 ${9 -
							monthPrePassCnt}개 적용되어 있으므로 ${monthPrePassCnt}개만 추가 적용 가능합니다.`
					})
					return false
				}
			}
			return true
		},

		// 다음: 결제정보 선택으로 이동
		async onRecpClick() {
			const mobOrderNo = this.$store.getters['verify/mobOrderNo']
			if (this.isParameterEmpty(mobOrderNo)) return
			let arrParam = {
				id: mobOrderNo, // 웹주문번호
				statusCode: '105'
			}
			const res = await mOrderInfoByEtc(arrParam)
			let msg = undefined
			msg = getMessageByStatusCode(res.resultObject.statusCode)
			if (msg !== undefined) {
				await this.$alert({
					message: msg
				})
				sessionStorage.removeItem('customer')
				this.$router
					.push({
						name: 'order-status',
						query: {
							user_no: sessionStorage.getItem('codyNoEnc'),
							dept_cd: this.$store.getters['common/deptCd'],
							type: this.$store.getters['common/type']
						}
					})
					.catch()
			} else {
				// 사전계약 관리 판매인 체크
				const preContractSaleCheck = await this.preContractSaleChk()
				if (!preContractSaleCheck) return

				// 제품별 작업 담당 조직 셋팅
				const delivery = this.$store.getters['schedule/deliveryInfos']
				const deliveryInfos = []
				let workerType = ''
				let prdOptionItemCdListStr = ''
				let preOwnerYm = ''
				let prodh = ''
				await this.products.forEach(item => {
					delivery.forEach(v => {
						if (v.goodsCd.includes(item.esProd.GOODS_CD)) {
							workerType = v.workerType
							prdOptionItemCdListStr = v.prdOptionItemCdListStr
							preOwnerYm = v.preOwnerYm
							prodh = v.prodh
						}
					})
					let deliveryInfo = {
						workerType: workerType,
						goodsCd: item.esProd.GOODS_CD,
						prodNm: item.esProd.GOODS_NM,
						prdOptionItemCdListStr: prdOptionItemCdListStr,
						preOwnerYm: preOwnerYm,
						prodh: prodh
					}
					deliveryInfos.push(deliveryInfo)
				})
				await this.$store.dispatch('schedule/setDeliveryInfos', deliveryInfos)

				if (this.products.length === 0) {
					this.$alert({ message: '결제대상 상품이 없습니다.' })
					return
				}

				if (!this.membershipPrepaymentValid()) return // 멤버십 선납할인 체크
				const checkPromotionRes = await this.checkPromotion() // 프로모션 체크
				if (!checkPromotionRes) return
				if (!this.checkInstallment()) return // 일시불 자체할부 체크
				if (!this.checkPrePass()) return // PRE-PASS 체크

				this.$store.dispatch('pay/saveRecpAmt', this.totalSumOfRecpAmt) // 상품 합계 수납 금액
				this.savePaymentForSAPRecp() // 수납SAP 전송을 위한 RECP_TP RECP_AMT2

				//매월 할인금액 스토어 저장
				this.$store.dispatch(
					'pay/saveMonthAmtDc',
					this.allPrdTotalAmt().saleMonthTotal
				)

				// 결합요금제 적용 유효성 검사
				const prdSaleInfoValidRes = await this.prdSaleInfoValidCheck()

				// POLICY_CHECK 성공시 IT_PAYMENT 생성
				if (prdSaleInfoValidRes.E_RETURN.TYPE === 'T') {
					// 자체할부 정보 갱신
					prdSaleInfoValidRes.IT_INPUT.forEach(v => {
						const prdIdx = parseFloat(v.I_SEQ) - 1
						this.products[prdIdx].payOptions.ET_INPUT.INS_MONTH = v.INS_MONTH
						this.products[prdIdx].payOptions.ET_INPUT.ACPT_PAY = v.ACPT_PAY
					})
					const status = { step: '04', statusCode: '105' }
					if (
						// 웹주문정보 IT_PAYMENT 저장
						!(await this.savePaymentForOrders(
							prdSaleInfoValidRes.IT_INPUT,
							status
						))
					)
						return
				}

				// 유효성 검사가 성공이면 결제정보 선택으로 이동
				if (prdSaleInfoValidRes.E_RETURN.TYPE === 'T') {
					// 결제정보 선택 이동
					this.$router.push({
						name: 'recp-method',
						params: { recpAmt: this.totalSumOfRecpAmt }
					})
				} else {
					this.$alert({ message: `${prdSaleInfoValidRes.E_RETURN.MESSAGE}` })
					return
				}
			}
		},

		//주문 시 유의사항
		onClickToggleNotice() {
			this.orderNoticeActive = !this.orderNoticeActive
		},

		// 할인 요금제(팝업)
		async prdDiscountPayment() {
			this.$refs.combi.open()
		},

		//동시구매할인 요금제(팝업)
		async prdSameDiscountPayment() {
			this.$refs.same.open()
		},

		//페스타 패키지(팝업)
		async prdFestaPayment() {
			this.$refs.festa.open()
		},

		//코웨이 패키지 할인(팝업)
		async prdFifteenPayment() {
			this.$refs.fifteen.open()
		},

		// 자동 할인 적용
		policySettingProducts() {
			const mobOrderNo = this.$store.getters['verify/mobOrderNo']
			if (this.isParameterEmpty(mobOrderNo)) return
			this.$confirm({
				message: '자동 할인을 다시 적용 하시겠습니까?',
				okText: '확인',
				cancelText: '취소'
			}).then(res => {
				if (res) {
					// 확인
					this.policySettingReq()
				} else {
					// 취소
				}
			})
		},

		// 자동할인 정보 받아오기
		async policySettingReq() {
			this.showSnackbar({
				msg: '할인이 자동으로 적용됩니다.',
				color: '#222'
			})

			const IT_INPUT = []
			await this.products.forEach((product, index) => {
				IT_INPUT.push({
					I_SEQ: this.$options.filters.numberPadding(index + 1, 4),
					CONTRACT_ID: product.esProd.CONTRACT_ID,
					SALE_ID: product.esProd.SALE_ID,
					GOODS_CD: product.esProd.GOODS_CD,
					USE_TP: product.esProd.USE_TP,
					REGI_ID: product.esProd.REGI_ID,
					REGI_GRADE: product.esProd.REGI_GRADE,
					FEE_START_NTH: product.esProd.FEE_START_NTH,
					RENTAL_YEARS: product.esProd.OWNER,
					LESGB: product.esProd.LESGB,
					ONCE_GRADE1: product.esProd.ONCE_GRADE1,
					ONCE_GRADE2: product.esProd.ONCE_GRADE2,
					ONCE_GRADE3: product.esProd.ONCE_GRADE3,
					PRICE: product.esProd.PRICE,
					MONTH_AMT: product.esProd.MONTH_AMT,
					PRE_ORDER_NO:
						product.options.preOrder === undefined
							? ''
							: product.options.preOrder.ORDER_NO,
					COMBI_NO: product.esProd.COMBI_NO,
					COMBI_SEQ: product.esProd.COMBI_SEQ,
					COMBI_GB: product.esProd.COMBI_GB,
					DC_RATE: product.esProd.DC_RATE,
					PACKAGE_ID: product.esProd.PACKAGE_ID,
					PACKAGE_SEQ: '',
					HB_YN: ['Z004'].includes(product.esProd.ORD_TYPE) ? 'Y' : 'N',
					MPRM_CODE: product.payOptions.returnPromotionApplied ? 'A067' : '',
					USE_DUTY_MONTH: product.esProd.USE_DUTY_MONTH,
					MEM_CHK:
						product.options.membershipType &&
						product.options.membershipType.code !== ''
							? 'X'
							: '',
					HOME2_ORDER_NO:
						product.options.homePreOrder === undefined
							? ''
							: product.options.homePreOrder.HOME2_ORDER_NO
				})
			})

			await policySetting({
				I_CONTRACT_CUST_ID: this.$store.getters['verify/custNo'],
				I_REGER_NO: this.$store.getters['common/codyNo'],
				I_PRE_CONTRACT: this.isPreContract ? 'X' : '',
				IT_INPUT
			})
				.then(res => {
					// 할인적용 재료로 사용하도록 응답 결과를 별도 저장
					this.productSaleInfo = res.resultObject.data
					this.$store.dispatch('pay/saveProductSaleInfo', this.productSaleInfo)
					// 기존 결합 적용된 주문
					const combiList03 = this.productSaleInfo.ET_COMBLIST_03.map(d => {
						return {
							code: d.COMBI_SEQ,
							text: '',
							type: 'existing', // 기존: existing, 신규: new
							sellType: this.getDiscountRentalType(d.SALE_GB).code,
							sellTypeText: this.getDiscountRentalType(d.SALE_GB).text,
							LESGB: '', // L: 리스, R: 렌탈
							MAKTX: '',
							oid2: '',
							combiYn: 'N',
							combiActive: '',
							packActive: '',
							festaActive: false,
							fifteenActive: false,
							...d
						}
					})
					this.$store.dispatch('pay/saveCombiExistingApplyPrd', combiList03)

					// 기존 결합 적용되지 않은 주문
					const combiList01 = this.productSaleInfo.ET_COMBLIST_01.map(d => {
						const combiData = this.getCombiList02Data(d.ORDER_NO)
						return {
							code: d.COMBI_SEQ,
							text: '',
							type: 'existing', // 기존: existing, 신규: new
							sellType: this.getDiscountRentalType(combiData.SALE_GB).code,
							sellTypeText: this.getDiscountRentalType(combiData.SALE_GB).text,
							LESGB: 'R', // L: 리스, R: 렌탈
							MAKTX: combiData.MAKTX,
							CHK30: combiData.CHK30,
							oid2: '',
							combiYn: 'Y',
							combiActive: true,
							packActive: false,
							festaActive: false,
							fifteenActive: false,
							...d
						}
					})
					this.$store.dispatch('pay/saveCombiExistingPrdAll', combiList01)

					// [동시구매할인] 당월 적용 주문
					const packList01 = this.productSaleInfo.ET_PACKLIST_01.map((d, i) => {
						const packData = this.getPackList02Data(d.ORDER_NO)
						if (['047', '048', '049', '050'].includes(d.PACKAGE_ID)) {
							return {
								code: this.$options.filters.numberPadding(i, 3),
								text: '',
								type: 'existing', // 기존: existing, 신규: new
								sellType: this.getDiscountRentalType(packData.SALE_GB).code, // db형 타입 코드
								sellTypeText: this.getDiscountRentalType(packData.SALE_GB).text, // 유형 타입 명칭
								LESGB: packData.LESGB, // L: 리스, R: 렌탈
								GOODS_NM: packData.GOODS_NM,
								oid2: '',
								combiActive: false,
								packActive: true, // 적용여부
								festaActive: false,
								fifteenActive: false,
								...d
							}
						} else {
							return {
								code: this.$options.filters.numberPadding(i, 3),
								text: '',
								type: 'existing', // 기존: existing, 신규: new
								sellType: this.getDiscountRentalType(packData.SALE_GB).code, // db형 타입 코드
								sellTypeText: this.getDiscountRentalType(packData.SALE_GB).text, // 유형 타입 명칭
								LESGB: packData.LESGB, // L: 리스, R: 렌탈
								GOODS_NM: packData.GOODS_NM,
								oid2: '',
								combiActive: false,
								packActive: false, // 적용여부
								festaActive: false,
								fifteenActive: false,
								...d
							}
						}
					})
					this.$store.dispatch('pay/savePackagePrd', packList01)

					const prdLists = this.products
					const etInput = []
					this.productSaleInfo.ET_INPUT.forEach(prd => {
						const prdIdx = parseFloat(prd.I_SEQ) - 1 // 상품 INDEX
						// 페스타 패키지 미적용 대상도 페스타 ID로 오기때문에 동시구매할인 ID로 변경
						const etInput2 = this.productSaleInfo.ET_INPUT_2.filter(
							v => v.I_SEQ === prd.I_SEQ
						)
						if (['048', '049', '050'].includes(prd.PACKAGE_ID)) {
							// 페스타 or 코웨이 패키지 할인은 15프로로 할인율 고정
							if (
								Number(prd.PACKAGE_RATE) < 15 &&
								etInput2.some(et => et.PACKAGE_ID === '047')
							) {
								prd.PACKAGE_ID = '047'
							}
						}
						this.$set(prdLists[prdIdx].payOptions, 'ET_INPUT', prd) // ET_INPUT 추가
						this.$set(prdLists[prdIdx].payOptions, 'ET_INPUT2', etInput2) // ET_INPUT2 추가

						if (prd.MPRM_CODE === 'A067') {
							prdLists[prdIdx].payOptions.promotion = prd.MPRM_CODE
						}
						// 재랜탈이 아니고 프로모션 코드가 있는 경우 프로모션 적용
						if (prdLists[prdIdx].payOptions.returnPromotionApplied === false) {
							if (
								prd.MPRM_CODE &&
								prd.MPRM_CODE !== '' &&
								this.products[prdIdx].payOptions.ET_ZSDS5204.length !== 0
							) {
								const applyPromotion = this.products[
									prdIdx
								].payOptions.ET_ZSDS5204.filter(
									v => v.PRM_CODE === prd.MPRM_CODE
								)[0]

								if (applyPromotion && applyPromotion !== '') {
									prdLists[prdIdx].payOptions.promotion = prd.MPRM_CODE // 프로모션 적용
									prdLists[prdIdx].payOptions.promotionDcAmt = this.isAllatOnce(
										prdLists[prdIdx]
									)
										? applyPromotion.LUMP_DC_AMT
										: applyPromotion.PRM_DC_AMT

									prdLists[prdIdx].payOptions.promotionName =
										applyPromotion.PRMNM
								} else {
									prdLists[prdIdx].payOptions.promotion = ''
									prdLists[prdIdx].payOptions.promotionDcAmt = 0
								}
							} else {
								prdLists[prdIdx].payOptions.promotionDcAmt = 0
							}
						}

						// 주문유형 처리
						// SALE_ID (0001: 일반, 00043: PRE-PASS, 그외 POLICY_SETTING RFC 참고)
						if (prd.SALE_ID === '0001') {
							prdLists[prdIdx].payOptions.orderType = 'G' // 일반

							this.$set(prdLists[prdIdx].payOptions, 'regFeePayMethod', 'A')
						} else if (prd.SALE_ID === '0043') {
							if (prd.PACKAGE_ID === '047') {
								prdLists[prdIdx].payOptions.orderType = 'PK' // 동시구매 패키지
							} else {
								prdLists[prdIdx].payOptions.orderType = 'P' // PRE-PASS
							}
						}

						// 사전계약 or 일반 결합할인 영역 활성화 조건
						const combiChk = this.isPreContract
							? prd.PRE_COMBI_NO !== ''
							: prd.COMBI_GB !== '' &&
							  !this.getCombListCheck(combiList01, combiList03)
						// PRE_COMBI_DC_RATE 필드는 SAP 유지보수를 위해 사전계약용으로 분리한 필드,
						// 프론트에서는 동일한 결합할인 %이므로 DC_RATE에 대입
						if (this.isPreContract) prd.DC_RATE = prd.PRE_COMBI_DC_RATE

						if (
							getCombiCheck(prdLists[prdIdx].options.sellType.code) &&
							!getCombiPrdhaCheck(prdLists[prdIdx].esProd.GOODS_CD) &&
							combiChk
						) {
							// 렌탈이고 COMBI_NO가 있는 경우 결합 할인적용 영역 적용 가능
							this.$set(prdLists[prdIdx].payOptions, 'combiActive', true)
							this.$set(prdLists[prdIdx].payOptions, 'combiDisplay', true)
						} else {
							// 그외 결합 할인 적용 불가
							this.$set(prdLists[prdIdx].payOptions, 'combiActive', false)
							this.$set(prdLists[prdIdx].payOptions, 'combiDisplay', false)
						}

						if (
							getPackCheck(prdLists[prdIdx].options.sellType.code) &&
							!getPackPrdhaCheck(prdLists[prdIdx].esProd.GOODS_CD) &&
							prd.PACKAGE_ID === '047'
						) {
							// PACKAGE_ID가 047이면 동시구매 할인적용 영역 적용 가능
							this.$set(this.products[prdIdx].payOptions, 'packActive', true)
							this.$set(this.products[prdIdx].payOptions, 'packDisplay', true)
							this.$set(this.products[prdIdx].payOptions, 'orderType', 'PK')
						} else {
							this.$set(this.products[prdIdx].payOptions, 'packActive', false)
							this.$set(this.products[prdIdx].payOptions, 'packDisplay', false)
						}

						// 렌탈이며, 페스타 패키지 적용 가능한 경우 설정 (패키지ID && 할인율 15%이상)
						if (
							prdLists[prdIdx].options.sellType.code === 'Z001' &&
							prdLists[prdIdx].payOptions.ET_INPUT2.some(
								v =>
									['048', '049'].includes(v.PACKAGE_ID) &&
									Number(v.PACKAGE_RATE) >= 15
							)
						) {
							this.$set(this.products[prdIdx].payOptions, 'festaDisplay', true)
							this.$set(this.products[prdIdx].payOptions, 'festaActive', false)
						} else {
							this.$set(this.products[prdIdx].payOptions, 'festaDisplay', false)
							this.$set(this.products[prdIdx].payOptions, 'festaActive', false)
						}

						// 렌탈이며, 코웨이 패키지 할인(050)이라면 설정
						if (
							prdLists[prdIdx].options.sellType.code === 'Z001' &&
							prdLists[prdIdx].payOptions.ET_INPUT2.some(
								v => v.PACKAGE_ID === '050' && Number(v.PACKAGE_RATE) >= 15
							)
						) {
							this.$set(
								this.products[prdIdx].payOptions,
								'fifteenDisplay',
								true
							)
							this.$set(
								this.products[prdIdx].payOptions,
								'fifteenActive',
								false
							)
						} else {
							this.$set(
								this.products[prdIdx].payOptions,
								'fifteenDisplay',
								false
							)
							this.$set(
								this.products[prdIdx].payOptions,
								'fifteenActive',
								false
							)
						}

						// 페스타 패키지 or 코웨이 패키지 할인 (렌탈만 진행 가능)
						// ET_INPUT 페스타 패키지 ID로 들어 왔지만 할인율 15% 미만이면 위에서 047로 변경
						if (
							['048', '049', '050'].includes(prd.PACKAGE_ID) &&
							prdLists[prdIdx].options.sellType.code === 'Z001'
						) {
							if (prd.PACKAGE_ID === '050') {
								this.$set(
									this.products[prdIdx].payOptions,
									'fifteenActive',
									true
								)
								this.$set(
									this.products[prdIdx].payOptions,
									'fifteenDisplay',
									true
								)
								this.$set(this.products[prdIdx].payOptions, 'orderType', 'FT')
							} else {
								this.$set(this.products[prdIdx].payOptions, 'festaActive', true)
								this.$set(
									this.products[prdIdx].payOptions,
									'festaDisplay',
									true
								)
								this.$set(this.products[prdIdx].payOptions, 'orderType', 'FP')
							}

							// 페스타 or 코웨이 패키지 할인 동시구매 설정
							if (
								prdLists[prdIdx].payOptions.ET_INPUT2.some(
									v => v.PACKAGE_ID === '047'
								)
							) {
								this.$set(this.products[prdIdx].payOptions, 'packDisplay', true)
								this.$set(this.products[prdIdx].payOptions, 'packActive', false)
							} else {
								this.$set(
									this.products[prdIdx].payOptions,
									'packDisplay',
									false
								)
								this.$set(this.products[prdIdx].payOptions, 'packActive', false)
							}

							// 페스타 or 코웨이 패키지 할인 결합할인 설정
							if (
								prdLists[prdIdx].payOptions.ET_INPUT2.some(
									v => v.COMBI_GB !== ''
								) &&
								!this.getCombListCheck(combiList01, combiList03)
							) {
								this.$set(prdLists[prdIdx].payOptions, 'combiActive', false)
								this.$set(prdLists[prdIdx].payOptions, 'combiDisplay', true)
							} else {
								this.$set(prdLists[prdIdx].payOptions, 'combiActive', false)
								this.$set(prdLists[prdIdx].payOptions, 'combiDisplay', false)
							}
						}

						//선납할인2 할인 초기화
						this.$set(prdLists[prdIdx].payOptions, 'downRentalPrepayAmt', '0')

						// 결합할인, 동시구매할인 상품정보 추가
						etInput.push({
							code: prd.COMBI_SEQ,
							text: prdLists[prdIdx].prd.leader_model_nm,
							type: 'new', // 기존: existing, 신규 : new
							sellType: prdLists[prdIdx].options.sellType.ORD_TYPE,
							sellTypeText: prdLists[prdIdx].options.sellType.TEXT,
							LESGB: prd.LESGB, // L : 리스, R: 렌탈
							MAKTX: prdLists[prdIdx].esProd.GOODS_NM,
							oid2: prdLists[prdIdx].oid2,
							combiYn: 'Y',
							packActive: prdLists[prdIdx].payOptions.packActive,
							combiActive: prdLists[prdIdx].payOptions.combiActive,
							festaActive: prdLists[prdIdx].payOptions.festaActive,
							fifteenActive: prdLists[prdIdx].payOptions.fifteenActive,
							PRDHA: prdLists[prdIdx].esProd.PRDHA,
							etInput2: prdLists[prdIdx].payOptions.ET_INPUT2,
							...prd
						})
					})

					this.products = prdLists
					this.$store.dispatch('pay/saveApplyPrdAll', [])
					this.$store.dispatch('pay/saveApplyPrd', etInput)
					this.$store.dispatch('pay/delAllPrd')
					this.$store.dispatch('pay/savePayPrd', prdLists)
					this.dcInfo()

					/**
					 * 재랜탈의 경우에만 처리
					 */
					setTimeout(() => {
						// 재랜탈 주문 건수
						const rerentalCnt = this.products.filter(
							p => p.esProd.ORD_TYPE === 'Z002'
						).length

						if (rerentalCnt > 0) {
							// 기존결합된 주문이 없음
							if (combiList03.length === 0) {
								let isCnt = 0

								const preOrderItems = this.products.filter(
									p =>
										p.esProd.ORD_TYPE === 'Z002' &&
										p.options.preOrderItems.length > 0
								)[0].options.preOrderItems
								this.$log('preOrderItems ===>>> ', preOrderItems)

								combiList01.forEach(v => {
									preOrderItems.forEach(k => {
										if (v.ORDER_NO === k.ORDER_NO) {
											isCnt += 1
											this.$log(
												`preOrder ${v.ORDER_NO}, combiList ${k.ORDER_NO}`
											)
										}
									})
								})

								if (isCnt > 0) {
									this.$alert({
										message:
											'다음달부터 결합요금제 할인율이 변경될 수 있습니다.'
									})
								}
							}

							// 기존결합된 주문이 있음
							if (combiList03.length > 0) {
								const orderIndex = []
								this.products.forEach((v, i) => {
									// 재랜탈
									if (v.esProd.ORD_TYPE === 'Z002') {
										combiList03.forEach(k => {
											if (v.options.preOrder.ORDER_NO === k.ORDER_NO) {
												orderIndex.push(i)
											}
										})
									}
								})

								if (orderIndex.length > 0) {
									let displayText = ''
									orderIndex.forEach((v, i) => {
										if (i === 0) {
											displayText += `${v + 1}`
										} else {
											displayText += `, 주문${v + 1}`
										}
									})

									this.$alert({
										message: `주문${displayText}은(는) 결합요금제에 적용되었습니다. 결합을 해제하면 기존주문을 대체하여 할인율을 그대로 적용받을 수 있습니다.`
									})
								}
							}
						}
					}, 1500)
				})
				.catch(err => {
					this.$log(err)
				})

			const status = { step: '04', statusCode: '104' }
			await this.savePaymentForOrders(this.productSaleInfo.ET_INPUT, status)
			this.$forceUpdate()
		},

		// itPackCombi 생성을 위한 DATA 호출
		getItPackData(orderList, orderNo) {
			return orderList.filter(v => v.ORDER_NO === orderNo)
		},

		// COMBLIST 유효 여부 체크
		getCombListCheck(combilist01, combilist03) {
			return combilist01.length === 0 && combilist03.length === 0
		},

		// COMBLIST_02 DATA 호출
		getCombiList02Data(ORDER_NO) {
			return this.productSaleInfo.ET_COMBLIST_02.filter(
				v => v.ORDER_NO === ORDER_NO
			)[0]
		},

		// PACKLIST_02 DATA 호출
		getPackList02Data(ORDER_NO) {
			return this.productSaleInfo.ET_PACKLIST_02.filter(
				v => v.ORDER_NO === ORDER_NO
			)[0]
		},

		// 전체삭제
		deleteAlldProduct() {
			this.$confirm({
				message:
					'상품을 모두 삭제하시겠습니까?<br />상품을 모두 삭제하시면, 상품  검색화면으로 이동 됩니다.',
				okText: '확인',
				cancelText: '취소'
			}).then(res => {
				if (res) {
					if (this.products.length === 0) {
						this.$router.push({ name: 'prd-search' })
					} else {
						this.$store.dispatch('pay/delAllPrd')
						this.$store.dispatch('prd/delAllPrd')
						this.products = []
						this.$router.push({ name: 'prd-search' })
					}
				}
			})
		},

		// 개별 삭제
		async deleteProduct(index) {
			let message = ''
			const p = this.products[index]
			const delProducts = [] // 삭제할 상품 oid, oid2

			// Delete Alert Message
			if (this.products.length === 1) {
				message =
					'상품을 모두 삭제하시겠습니까?<br />상품을 모두 삭제하시면, 상품  검색화면으로 이동 됩니다.'
			} else {
				message =
					'상품을 삭제하시면 잔여 주문으로 자동 추천 할인이 다시 적용 됩니다. 적용하시겠습니까?'
			}

			await this.$alert({
				message,
				okText: '확인',
				cancelText: '취소'
			}).then(res => {
				this.$log('DEL RES', res)
				if (res) {
					// 삭제할 상품 정보 추가
					delProducts.push({
						oid: p.oid,
						odi2: p.oid2
					})
				}

				if (res) {
					delProducts.forEach(delPrd => {
						let cnt = 0
						this.products.forEach((prd, prdIdx) => {
							if (prd.oid === delPrd.oid && cnt === 0) {
								// 할인 선택의 상품 삭제(STORE)
								this.$store.dispatch('pay/delPayOid2', delPrd.oid2)
								// 상품 선택의 상품 수량 변경 또는 삭제(STORE)
								this.$store.dispatch('prd/delPrdOid', delPrd.oid)
								// 상품 리스트 데이터 삭제
								this.products.splice(prdIdx, 1)
								cnt += 1
							}
						})
					})

					// 상품이 없으면 상품 검색으로 이동, 있으면 자동할인 재적용
					if (this.products.length === 0) {
						this.$router.push({ name: 'prd-search' })
					} else {
						this.policySettingReq()
					}
				}
			})
		},

		// 주문유형 선택
		handleOrderTypeSelected(orderIndex, item) {
			this.$log('ITEM', item)
			const p = this.products[orderIndex]
			if (item) {
				if (item.code === 'G') {
					// 주문유형 일반이면 pre-pass 할인제거
					this.removePrePass(p)
					// 등록비납부 기본값 일시불로 설정
					if (!p.payOptions.regFeePayMethod) {
						this.$set(p.payOptions, 'regFeePayMethod', 'A')
					}
				} else {
					if (item.code === 'C') {
						// CRP일 경우
						this.$refs.ceo[0].open()
					} else if (item.code === 'P') {
						// Pre-Pass 일 경우
						this.calcRegFee(p)
						this.$set(
							this.products[orderIndex].payOptions,
							'regFeePayMethod',
							undefined
						)
						this.addIfNotExist(p.payOptions, 'priceDcTypes', '01')
						// 등록비분납 서비스 제거
						this.removeRegFeeInstallment(p)
					} else if (
						item.code === 'PK' ||
						item.code === 'FP' ||
						item.code === 'FT'
					) {
						this.removePrePass(p)
						// 등록비분납 서비스 제거
						this.removeRegFeeInstallment(p)
					}
					this.savePayProductOption(
						orderIndex,
						this.products[orderIndex].payOptions
					)
				}
			}
			this.handleFecthPayOption(orderIndex)
			this.dcInfo()
		},

		// 기존주문번호 선택
		handleCRPExistingOrder(orderIndex, item) {
			this.$log('handleCRPExistingOrder selected item:', item)
		},

		// 옵션정보 갱신
		async handleFecthPayOption(orderIndex) {
			const p = this.products[orderIndex]

			await this.fetchPayOptionsForProduct(p)
			if (this.isSelfInstallment(p)) {
				this.$set(p.payOptions, 'installmentMonth', '')
			}

			if (this.isRental(p)) {
				this.$set(p.payOptions, 'prepayMonth', '01')
				this.$set(p.payOptions, 'prepayAmt', 0)

				this.$set(
					p.payOptions,
					'priceAddTypes',
					p.payOptions.priceAddTypes.filter(x => x !== '04')
				)
				this.$set(
					p.payOptions,
					'priceDcTypes',
					p.payOptions.priceDcTypes.filter(x => x !== '03')
				)
			}

			this.dcInfo()
		},

		// 선납 할인 선택
		handlePrepayMonthSelected(orderIndex, item) {
			this.products[orderIndex].payOptions.prepayMonth = item.PRE_MONTH
			this.products[orderIndex].payOptions.prepayAmt = Number(item.RECP_AMT2)
			this.products[orderIndex].payOptions.prepayGoalAmt = Number(
				item.GOAL_AMT2
			)

			this.dcInfo()
		},
		handleDownRentalSelected(orderIndex, item) {
			this.products[orderIndex].payOptions.downRentalPrepayAmt = item.PRE_AMT
			this.products[orderIndex].payOptions.preGb = item.PREGB
			this.products[orderIndex].payOptions.downRentalSelected = item

			this.dcInfo()
		},

		// 잔액 할부 개월 선택
		async handleBalanceInstallmentMonthSelected(orderIndex, item) {
			const p = this.products[orderIndex]
			p.payOptions.installmentMonth = item.code
			this.$nextTick(async () => {
				if (!this.installmentValid(p)) return
				await this.fetchPayOptionsForProduct(p)
			})
			this.dcInfo()
		},

		// 프로모션 할인 선택
		handlePromotionDiscountSelected(orderIndex, item) {
			const downRentalChk =
				item.code != '' &&
				this.products[orderIndex].payOptions.downRentalPrepayAmt !== '0' &&
				item.DOWN_YN !== 'Y'

			const festaPackagePrmChk =
				item.code !== '' &&
				this.products[orderIndex].payOptions.festaActive &&
				item.PRM_CODE !== 'A141'

			const fifteenPackagePrmChk =
				item.code !== '' &&
				this.products[orderIndex].payOptions.fifteenActive &&
				!['A141', 'A317', 'A339'].includes(item.PRM_CODE)

			if (downRentalChk || festaPackagePrmChk || fifteenPackagePrmChk) {
				const msg = fifteenPackagePrmChk
					? '코웨이 패키지 할인 5%와 프로모션을 함께 적용하려면 코웨이 패키지 할인을 해제하고 동시구매할인 적용 후 프로모션을 선택해 주세요'
					: '선택되어있는 할인제도와 중복적용 할 수 없습니다.판매 정책을 확인해주세요'
				this.$alert({
					message: msg
				})
				return
			}
			const p = this.products[orderIndex]
			this.applyPromotionDc(p, { ...item })
			this.handleFecthPayOption(orderIndex)
		},

		// 멤버십 선납 할인 선택
		handleMemberShipDiscountSelected(orderIndex, item) {
			const p = this.products[orderIndex]

			let code = item.code
			let preAmt = item.preAmt
			let monthAmt = item.monthAmt
			let amtDc = item.amtDc
			let goalAmt = item.goalAmt
			let preMonth = item.preMonth

			this.addIfNotExist(p.payOptions, 'priceAddTypes', '02')

			if (item.code === '00') {
				if (p.options.membershipType) {
					monthAmt = p.options.membershipType.MON_AMT
				}
			} else if (item.code === '01') {
				// 1년 선납이면
				this.addIfNotExist(p.payOptions, 'priceDcTypes', '05')
			} else if (p.payOptions.promotion === 'A127') {
				// 1년 의무사용
				this.addIfNotExist(p.payOptions, 'priceAddTypes', '05')
			}

			this.$set(p.payOptions, 'msDc', code)
			this.$set(p.payOptions, 'msPreAmt', preAmt)
			this.$set(p.payOptions, 'msMonthAmt', monthAmt)
			this.$set(p.payOptions, 'msAmtDc', amtDc)
			this.$set(p.payOptions, 'msGoalAmt', goalAmt)
			this.$set(p.payOptions, 'msPreMonth', preMonth)
			this.$set(p.payOptions, 'membership', '01')
			this.$set(p.payOptions, 'selectedMemberShipDiscountItem', item)

			this.handleFecthPayOption(orderIndex)

			setTimeout(() => {
				this.dcInfo()
			}, 200)
		},

		// 숫자만 입력 가능
		numberFormatDel(param) {
			param.target.value = param.target.value.replace(/[^0-9]/g, '')
		},

		// 자체할부 계약금 최소/최대/특문제거
		handleMinMaxAcptAmtBlur(p) {
			const acptAmtTemp = Number(p.payOptions.displayAcptAmt)
			const acptAmt = Math.floor(acptAmtTemp / 1000) * 1000
			const min = Number(p.valueOptions.min)
			const max = Number(p.valueOptions.max)

			if (acptAmt < min) {
				// 최소금액
				const temp = min.toString().replace(/[^0-9]/g, '')
				p.payOptions.displayAcptAmt = temp.replace(
					/(\d)(?=(\d{3})+(?!\d))/g,
					'$1,'
				)
				p.payOptions.acptAmt = Number(temp)
			} else if (acptAmt > max) {
				// 최대금액
				const temp = max.toString().replace(/[^0-9]/g, '')
				p.payOptions.displayAcptAmt = temp.replace(
					/(\d)(?=(\d{3})+(?!\d))/g,
					'$1,'
				)
				p.payOptions.acptAmt = Number(temp)
			} else {
				const temp = acptAmt.toString().replace(/[^0-9]/g, '')
				p.payOptions.displayAcptAmt = temp.replace(
					/(\d)(?=(\d{3})+(?!\d))/g,
					'$1,'
				)
				p.payOptions.acptAmt = Number(temp)
			}
			this.dcInfo()
		},

		// 일시불 체크
		isAllatOnce(p) {
			const installSellTypes = ['Z003']
			const sellType = p.options.sellType.code
			return installSellTypes.includes(sellType)
		},

		// 렌탈 체크
		isRental(p) {
			const rentalSellTypes = ['Z001', 'Z002']
			if (p.options) {
				const sellType = p.options.sellType.code
				return rentalSellTypes.includes(sellType)
			}
		},

		// 자체할부 여부
		isSelfInstallment(p) {
			const installSellTypes = ['Z004']
			const sellType = p.options.sellType.code
			return installSellTypes.includes(sellType)
		},

		// 멤버십 여부
		isSelfSinglePayment(p) {
			const installSellTypes = ['Z003', 'Z004', 'Z005']
			let sellType = ''
			if (
				p.payOptions.membership &&
				p.payOptions.membership === '01' &&
				p.payOptions.msDc === ''
			) {
				sellType = p.options.sellType.code
			}
			return installSellTypes.includes(sellType)
		},

		// 제랜탈 여부
		isReRental(p) {
			const sellType = p.options.sellType.code
			return sellType === 'Z002'
		},

		// 프로모션 적용
		applyPromotionDc(p, { code, text, dcAmt }) {
			if (code === '') {
				// 선택 프로모션 없음
				this.$set(
					p.payOptions,
					'priceDcTypes',
					p.payOptions.priceDcTypes.filter(x => x !== '02')
				)
				this.$set(p.payOptions, 'promotion', '')
				this.$set(p.payOptions, 'msDc', '00')
				this.$set(p.payOptions, 'promotionDcAmt', '')
				this.$set(p.payOptions.ET_INPUT, 'MPRM_CODE', '')

				// 상품선택에서 선택된 멤버십이 있으면 멤버십 가입처리
				if (p.options.membershipType && p.options.membershipType.code !== '') {
					if (p.options.membershipType) {
						this.applyMembershipJoin(p, {
							code: p.options.membershipType.code,
							amt: p.options.membershipType.MON_AMT,
							useTp: p.options.membershipType.USE_TP
						})
					}
				}
			} else {
				// 선택 프로모션 있음
				this.addIfNotExist(p.payOptions, 'priceDcTypes', '02')
				this.$set(p.payOptions, 'promotion', code)

				if (p.payOptions.ET_INPUT)
					this.$set(p.payOptions.ET_INPUT, 'MPRM_CODE', code)

				this.$set(p.payOptions, 'promotionName', text.slice(0, 14))
				this.$set(p.payOptions, 'promotionDcAmt', dcAmt)

				// 일시불 1년무상 프로모션이면 멤버십 미가입처리
				if (p.payOptions.promotion === 'A127') {
					this.$set(p.payOptions, 'msDc', '00')
					this.$set(p.payOptions, 'msPreAmt', 0)
					this.$set(p.payOptions, 'msMonthAmt', 0)
					this.$set(p.payOptions, 'msAmtDc', 0)
					this.$set(p.payOptions, 'membership', '00')
				}
			}

			this.dcInfo()
		},

		// 멤버십 가입처리
		applyMembershipJoin(p, item) {
			if (!item.code || item.code === '00') {
				//멤버십 미가입
				this.$set(
					p.payOptions,
					'priceAddTypes',
					(p.payOptions.priceAddTypes || []).filter(x => x !== '02')
				)
				this.$set(p.payOptions, 'msDc', '00')
				this.$set(p.payOptions, 'msAmtDc', 0)
				this.$set(p.payOptions, 'msPreAmt', 0)
				this.$set(p.payOptions, 'msMonthAmt', 0)
				this.$set(p.payOptions, 'membership', '00')
			} else {
				// 멤버십 가입
				this.$set(p.payOptions, 'msDc', '00') // 선납 여부 (00 : 선납 없음, 01: 선납 있음)
				this.$set(p.payOptions, 'memUseTp', item.useTp)
				this.$set(p.payOptions, 'msMonthAmt', Number(item.amt))
				this.$set(p.payOptions, 'membership', '01')
				this.addIfNotExist(p.payOptions, 'priceAddTypes', '02')
			}
		},

		// 멤버십 선납 할인 선택 출력
		shouldMembershipDisplayed(p) {
			// 멤버십 선택항목 표시여부(일시불이고 정수기/매트리스 아닌 경우 정수기/매트리스는 자동가입처리)
			// 멤버십 가입금액 0보다 큰 경우 추가
			// 일시불 프로모션중 멤버십 1년무상 프로모션(A127) 이 아닐 경우 추가
			const autoMembershipCategories = [399, 403]
			return (
				!this.isRental(p) &&
				!autoMembershipCategories.includes(p.prd.display_cate2) &&
				p.options.membershipType &&
				Number(p.options.membershipType.MON_AMT) > 0 &&
				p.payOptions.promotion !== 'A127'
			)
		},

		// 프로모션 할인 출력 여부
		shouldPromotionDisplayed(p) {
			// 계약금
			this.minInsAmt(p)

			//프로모션항목 표시여부
			return (
				this.products.length > 0 &&
				p.payOptions.ET_ZSDS5204 &&
				p.payOptions.ET_ZSDS5204.length > 0 &&
				p.payOptions.returnPromotionApplied === false
			)
		},

		// 렌탈 선납 할인 출력 여부
		shouldPrepayMonthDisplayed(p) {
			return p.esProd.ORD_TYPE === 'Z001' || p.esProd.ORD_TYPE === 'Z002'
		},

		// 해당 category의 서비스별 추가금액
		appendedPayServicesAmountByCategory(category) {
			let sumOfRecpAmt = 0
			let sumOfMonthAmt = 0
			const services = this.appendedPayServices.map(s => {
				return { ...s, sumOfRecpAmt, sumOfMonthAmt }
			})
			this.products.forEach(p => {
				if (p.prd.display_cate2 === category.code) {
					const pats = p.payOptions.priceAddTypes || []
					pats.forEach(pat => {
						switch (pat) {
							case '01': {
								//부가서비스
								const service = services.find(s => s.code === pat)
								service.sumOfMonthAmt +=
									p.payOptions.heartServiceMonthAmt * p.payOptions.quantity
								break
							}
							case '02': {
								//멤버십
								const service = services.find(s => s.code === pat)
								service.sumOfRecpAmt +=
									p.payOptions.msPreAmt ?? 0 * p.payOptions.quantity
								// service.sumOfMonthAmt +=
								// 	p.payOptions.msMonthAmt * p.payOptions.quantity
								service.sumOfMonthAmt += this.calcMembershipMonthAmt(p)
								break
							}

							case '04': {
								//선납금
								const service = services.find(s => s.code === pat)
								service.sumOfRecpAmt += this.calcPrepayRecpAmt2(p)
								// service.sumOfMonthAmt += this.calcPrepayDc(p)
								break
							}
							case '06': {
								//일시불분납
								const service = services.find(s => s.code === pat)
								// service.sumOfRecpAmt += this.calcPrepayRecpAmt2(p)
								service.sumOfMonthAmt += 10000 * p.payOptions.quantity
								break
							}
							case '07': {
								//반납위약금
								const service = services.find(s => s.code === pat)
								service.sumOfRecpAmt += p.payOptions.returnPenaltyAmt
								break
							}
							default:
						}
					})
				}
			})
			// this.$log('services', category.name, services)
			return services.filter(s => s.sumOfRecpAmt > 0 || s.sumOfMonthAmt > 0)
		},

		// 상품 옵션 정보 저장
		savePayProductOption(orderIndex, payOptions) {
			this.$log('savePayProductOption', orderIndex, payOptions)
			this.$store.dispatch('pay/savePayPrdOptions', { orderIndex, payOptions })
		},

		// 주문유형 출력 텍스트
		orderTypeDisplayText(product) {
			let { orderType, regFeePayMethod } = product.payOptions
			if (orderType === undefined) {
				orderType = 'P'
			}

			this.calcRegFee(product)
			const items = this.orderTypeItemList.filter(x => x.code === orderType)
			if (items) {
				const item = items[0]
				switch (item.code) {
					case 'N':
						return item.text
					case 'C':
					case 'P':
						return `${item.text} 등록비-100,000원`
					case ('PK', 'FP', 'FT'):
						return `${item.text} -100,000원`
					case 'G':
						return `${item.text} (등록비 일시불)`
					case 'G1': {
						const lumSum =
							this.regFeePayMethodItems.filter(
								x => x.code === regFeePayMethod
							)[0] || {}
						const lumSumText = lumSum.text
						return lumSumText ? `${item.text} / ${lumSumText}` : item.text
					}
				}
				return `${item.text}`
			}

			return '주문유형 선택'
		},

		// 선납 할인 선택 텍스트
		prepayDisplayText(prepayMonth) {
			if (!prepayMonth) return '선납 할인 선택'
			const items = this.prepayMonthItems.filter(x => x.code === prepayMonth)
			if (items) {
				const item = items[0]
				return item.text
			}
		},

		// 선납 할인 금액
		calcPrepay(product) {
			if (
				!(
					product.payOptions.prepayMonth &&
					product.payOptions.T_PRE_AMT &&
					product.payOptions.prepayMonth !== '01'
				)
			) {
				return 0
			}
			return product.payOptions.T_PRE_AMT.find(
				x => x.PRE_MONTH === product.payOptions.prepayMonth
			).RECP_AMT2
		},

		// 선납할인2 할인 금액
		calcDownRental(product) {
			if (
				!(
					product.payOptions.downRentalPrepayAmt &&
					product.payOptions.ET_ZSDS6087 &&
					product.payOptions.downRentalPrepayAmt !== '0'
				)
			) {
				return 0
			}
			return product.payOptions.ET_ZSDS6087.filter(
				x => x.PRE_AMT === product.payOptions.downRentalPrepayAmt
			)[0].PRE_AMT
		},

		//선납 금액
		calcPrepayRecpAmt2(p) {
			const etzsd2368 = p.payOptions.T_PRE_AMT
			if (
				!(
					p.payOptions.prepayMonth &&
					Array.isArray(etzsd2368) &&
					etzsd2368.length &&
					p.payOptions.prepayMonth !== '01'
				)
			) {
				return 0
			}

			const data = p.payOptions.T_PRE_AMT.find(
				x => x.PRE_MONTH === p.payOptions.prepayMonth
			)
			const { RECP_AMT2: recpAmt2 } = data
			return +recpAmt2 * p.payOptions.quantity
		},

		calcPrepayDc(product) {
			//렌탈료 선납에 의한 선납기간동안의 매월 할인효과 금액
			const etzsd2368 = product.payOptions.T_PRE_AMT
			if (
				!(
					product.payOptions.prepayMonth &&
					Array.isArray(etzsd2368) &&
					etzsd2368.length &&
					product.payOptions.prepayMonth !== '01'
				)
			) {
				return 0
			}

			const data = product.payOptions.T_PRE_AMT.find(
				x => x.PRE_MONTH === product.payOptions.prepayMonth
			)

			const {
				MON_FI_AMT: monFiAmt,
				RECP_AMT2: recpAmt2,
				PRE_MONTH2: preMonth
			} = data
			return Math.round(monFiAmt * preMonth - recpAmt2)
		},

		calcDownRentalDc(product) {
			//렌탈료 선납에 의한 선납기간동안의 매월 할인효과 금액
			const data = product.payOptions?.ET_ZSDS6087?.filter(
				x => x.PRE_AMT === product.payOptions.downRentalPrepayAmt
			)[0]

			return data?.MONTH_DC || 0
		},

		calcPrepayMonthAmt(p) {
			const etzsd2368 = p.payOptions.T_PRE_AMT
			if (
				!(
					p.payOptions.prepayMonth &&
					Array.isArray(etzsd2368) &&
					etzsd2368.length &&
					p.payOptions.prepayMonth !== '01'
				)
			) {
				return 0
			}
			const data = p.payOptions.T_PRE_AMT.find(
				x => x.PRE_MONTH === p.payOptions.prepayMonth
			)
			const { MON_FI_AMT: prepayMonthAmt } = data
			return +prepayMonthAmt * p.payOptions.quantity
		},

		calcPrepayRecpDcAmt(product) {
			//렌탈료 선납에 의한 수납할인금액
			if (!(product.payOptions.prepayMonth && product.payOptions.T_PRE_AMT)) {
				return 0
			}
			const data = product.payOptions.T_PRE_AMT.find(
				x => x.PRE_MONTH === product.payOptions.prepayMonth
			)
			const { GOAL_AMT2: goalAmt, RECP_AMT2: recpAmt } = data
			return (goalAmt - recpAmt) * product.payOptions.quantity
		},

		calcMonFiAmt(p) {
			// const rentalAmt = p.payOptions.rentalAmt ?? +p.esProd.MONTH_AMT
			const rentalAmt = this.promotionAppliedRentalAmt(p)
			// 정기결제 있을 경우 렌탈료선납금액 계산을 위해 SAP 호출할 경우 1000원 뺀 금액으로 호출
			return p.payOptions.regularPayment ? rentalAmt - 1000 : rentalAmt
		},

		calcMembership(product) {
			if (product.payOptions.msDc === '01') {
				// 멤버십할인 1년 선납이면 멤버십 항목 0 으로 표시
				return 0
			}
			const monthAmt = product.payOptions.msMonthAmt
			const dc = product.payOptions.msAmtDc ? product.payOptions.msAmtDc : 0
			return (monthAmt - dc) * product.payOptions.quantity
		},

		calcMembershipAfterOneYear(p) {
			const monthAmt = p.payOptions.msMonthAmt
			return monthAmt * p.payOptions.quantity
		},

		calcMembershipMonthAmt(p) {
			// 멤버십 매월 납부금액
			if (!p.payOptions.membership || p.payOptions.membership === '00') return 0
			const monthAmt = p.payOptions.msMonthAmt
			if (p.payOptions.msDc === '00') {
				return monthAmt
			}
			return monthAmt * p.payOptions.quantity
		},

		// 월 할부금 계산(자체할부일 경우)
		calcInstallMonthPay(p) {
			const etZsd1513 = p.payOptions.ET_ZSDS1513
			if (Array.isArray(etZsd1513) && etZsd1513.length > 0) {
				return etZsd1513[0].M_TOT_PAY
			}

			if (!p.payOptions.acptAmt || !p.payOptions.installmentMonth) {
				if (+this.pkg.dcRate) {
					return this.pkg.dcRate / 100
				} else if (!p.payOptions.acptAmt) {
					return p.esProd.PRICE
				} else if (p.payOptions.acptAmt) {
					return p.esProd.PRICE - p.payOptions.acptAmt
				}
			}

			return 0
		},

		// 자체할부 할부 개월수
		calcInstallmentMonthItems(p) {
			const zsds1518 = p.payOptions.ET_ZSDS1518
			if (Array.isArray(zsds1518) && zsds1518.length > 0) {
				this.$set(p.valueOptions, 'max', parseInt(zsds1518[0].ACPT_PAY_MAX))
				this.$set(p.payOptions, 'acptCode', zsds1518[0].ACPT_CODE)
				this.$set(p.payOptions, 'monthsCode', zsds1518[0].MONTHS_CODE)
				this.$set(p.payOptions, 'rateCode', zsds1518[0].RATE_CODE)
				const monMin = +zsds1518[0].MONTHS_MIN
				const monMax = +zsds1518[0].MONTHS_MAX
				return this.installmentMonthItems.filter(
					x => x.code >= monMin && x.code <= monMax
				)
			}
			return this.installmentMonthItems
		},

		// 웹디비 저장
		onSaveTempClick(closeWindow = false) {
			if (this.products.length === 0) {
				this.$alert({ message: '저장할 할인선택 상품이 없습니다' })
				return
			}
			const pay = { ...this.$data }

			this.$log('onSaveTempClick', pay)
			localStorage.setItem('pay', JSON.stringify(pay))

			this.saveWebOrderInfo(
				{ step: '03', statusCode: '104' },
				{
					pay
				}
			)
			this.$alert({ message: '할인선택이 임시저장 되었습니다' }).then(() => {
				if (!closeWindow) {
					this.$router.push({ name: 'order-status' })
				}
			})
		},

		// 부가서비스
		addIfNotExist(payOptions, prop, value) {
			if (!(payOptions[prop] || []).includes(value)) {
				this.$set(payOptions, prop, [...(payOptions[prop] || []), value])
			}
		},

		showRegFeePayMethod(product) {
			// 등록비납부 방법 표시되어야 하는지 여부(렌탈->일반 선택또는 재렌탈일 경우 보인다)
			const sellType = product.options.sellType.code
			// this.$log('showRegFeePayMethod sellType:', sellType, product.payOptions)
			return product.payOptions.orderType === 'G' && sellType === 'Z001'
		},

		async setupPayOptions() {
			for (const p of this.products) {
				// promises = [...promises, this.fetchPayOptionsForProduct(p)]
				this.$log('fetchPayOptionsForProduct 1')
				await this.fetchPayOptionsForProduct(p)

				if (this.shouldPromotionDisplayed(p)) {
					this.$set(p.payOptions, 'promotion', '')
					this.$set(p.payOptions, 'promotionDcAmt', 0)
				} else {
					this.promotionAppliedRentalAmt(p)
				}

				// 멤버십 선납 할인 선택 출력을 위한 설정
				if (p.options.membershipType && p.payOptions.promotion !== 'A127') {
					// 선택한 멤버십 코드
					this.$set(p.payOptions, 'membership', p.options.membershipType.code)
				} else {
					// 선택한 멤버십 없음
					this.$set(p.payOptions, 'membership', '00')
				}

				// 자체할부
				if (this.shouldEarnestMoneyDepositDisplayed(p)) {
					this.$set(p.payOptions, 'acptAmt', '')
				}

				// 렌탈 선납할인 기본값 선납 할인 선택 없음
				if (this.isRental(p)) {
					this.$set(p.payOptions, 'prepayMonth', '01')
					this.$set(p.payOptions, 'downRentalPrepayAmt', '0')
				}
			}
		},

		async fetchPayOptionsForProduct(p) {
			const kunnr = this.$store.getters['verify/custNo']
			const {
				GOODS_CD: goodsCd,
				USE_TP: useTp,
				REGI_ID: regiId,
				REGI_GRADE: regiGrade,
				OWNER: rentalYears,
				LESGB: lesgb,
				CONTRACT_ID: contractId,
				GOODS_ID: goodsId,
				REG_PRICE: regPrice,
				SALE_ID: saleId
			} = p.esProd
			let productOptions = {
				kunnr,
				goodsCd,
				useTp,
				regiId,
				regiGrade,
				rentalYears,
				saleId,
				lesgb,
				contractId,
				goodsId,
				orderQty: p.payOptions.quantity,
				regPrice,
				hbYn: p.options.sellType.code === 'Z004' ? 'Y' : '',
				preOrderNo:
					p.options.preOrder === undefined ? '' : p.options.preOrder.ORDER_NO,
				memChk:
					p.options.membershipType && p.options.membershipType.code !== ''
						? 'X'
						: '',
				packId: '',
				saleUemplNo: this.$store.getters['common/codyNo'],
				home2OrderNo:
					p.options.homePreOrder === undefined
						? ''
						: p.options.homePreOrder.HOME2_ORDER_NO,
				placeId2:
					p.options.chairMultiFac === undefined
						? ''
						: p.options.chairMultiFac.code
			}

			if (contractId === '1') {
				// 일시불일 경우 일시불 가격등급을 전달하여 프로모션 할인액을 받아온다
				productOptions = {
					...productOptions,
					onceGrade1: p.esProd.ONCE_GRADE1,
					onceGrade2: p.esProd.ONCE_GRADE2
				}
			}

			if (contractId === '2') {
				// 렌탈이면 렌탈료 보내서 선납할인 금액 받아온다
				productOptions = { ...productOptions, monFiAmt: this.calcMonFiAmt(p) }
			}

			// 자체할부일때 가능 할부개월수 가져오기 위해
			if (this.isSelfInstallment(p)) {
				productOptions = {
					...productOptions,
					goodsAmt: p.esProd.PRICE * p.payOptions.quantity
				}
			}

			if (p.payOptions.acptAmt && p.payOptions.installmentMonth) {
				const { acptAmt, installmentMonth: months } = p.payOptions
				const goodsAmt = p.esProd.PRICE * p.payOptions.quantity
				productOptions = { ...productOptions, acptAmt, goodsAmt, months }
				this.$log('productOptions', productOptions)
			}

			// 자체할부일때 가능 할부개월수 가져오기 위해 최초 한번 호출
			if (this.isSelfInstallment(p)) {
				const zsd1518 = p.payOptions.ET_ZSDS1518
				if (!zsd1518) {
					const goodsAmt = p.esProd.PRICE * p.payOptions.quantity
					productOptions = { ...productOptions, goodsAmt }
				}
			}

			try {
				// 할인선택 항목별 옵션 조회
				const res = await fetchPayOptions([productOptions])
				// this.$log(res)
				const { data } = res.resultObject
				if (data && data.TYPE === 'E') {
					const message = data.MESSAGE
					this.$alert({
						message
					})
					return
				}

				let {
					ET_ZSDS6087,
					ET_ZSDS5204,
					ET_ZSDS1802,
					ET_ZSDS2368,
					ET_ZSDS5205,
					ET_ZSDS5206,
					ET_ZSDS1513,
					ET_ZSDS5230,
					ET_ZSDS1518
				} = data
				ET_ZSDS5204 = this.pkg.id ? [] : [...ET_ZSDS5204]
				// https://jira.nmn.io/browse/CWMJPRO-648 자체할부, 일시불보상판매 프로모션 안 쓰게 방어로직 추가
				ET_ZSDS5204 = ['Z004', 'Z005'].includes(p.esProd.ORD_TYPE)
					? []
					: [...ET_ZSDS5204]
				p.payOptions = {
					...p.payOptions,
					ET_ZSDS6087,
					ET_ZSDS5204,
					ET_ZSDS1802,
					ET_ZSDS2368,
					ET_ZSDS5205,
					ET_ZSDS5206,
					ET_ZSDS1513,
					ET_ZSDS5230,
					ET_ZSDS1518
				}
			} catch (err) {
				this.$log(err)
			}

			// this.$log('product after SAP call:', p)
			return p
		},

		// 상품 월 렌탈료
		calcRental(product) {
			// const base =
			// 	product.payOptions.regFeePayMethod === 'L'
			// 		? +product.esProd.MONTH_AMT + 10000
			// 		: +product.esProd.MONTH_AMT

			// 선납금에 의한 렌탈료 DC
			const prepayDc = this.calcPrepayDc(product)

			return product.esProd.MONTH_AMT - prepayDc
		},

		calcRegFee(p) {
			const orderType = p.payOptions

			const base =
				// regFeePayMethod === 'L' ||
				orderType === 'P' || p.options.sellType.code === 'Z002'
					? 0
					: +p.esProd.REG_PRICE * p.payOptions.quantity
			return base
		},

		calcRentalWithNoService(p) {
			const base =
				// (p.payOptions.regFeePayMethod === 'L'
				// 	? +p.esProd.MONTH_AMT + 0
				// 	:
				+p.esProd.MONTH_AMT *
				// )
				p.payOptions.quantity

			//선납금에 의한 렌탈료 DC
			// const prepayDc = this.calcPrepayDc(p)

			return base
		},

		// 웹주문정보중 IT_PAYMENT 저장
		async savePaymentForOrders(IT_INPUT, status) {
			const paymentOrders = this.products.map((p, index) => {
				const GOODS_CD = p.esProd.GOODS_CD // 제품코드
				const MEM_MONTH_AMT = p.payOptions.msMonthAmt ?? 0 // 멤버십 월 납입 금액
				const PRM_CODE = p.payOptions.returnPromotionApplied
					? 'A067'
					: p.payOptions.promotion ?? ''
				const MONTH_AMT = this.displayPrdTotalAmt(p.saleInfo).prdMonthTotalNum // 월 렌탈료
				const ADSVC = p.options.prdOption1?.ADSVC ?? '' // 부가서비스 구분
				const ACPT_AMT = p.payOptions.acptAmt ?? 0 // 인수금
				// 등록비 분납일 경우 INS_MONTHS 에 10 설정
				const INS_MONTHS = p.payOptions.installmentMonth ?? 0
				const M_TOT_PAY = this.calcInstallMonthPay(p) // 자체할부 월 할부금
				const PRE_MONTH = p.payOptions.prepayMonth ?? '01' // 선납 개월 (01은 선납선택 안함)
				const PRE_AMT = this.calcPrepay(p) // 선납금액
				const PACKAGE_ID = IT_INPUT.filter(
					v => parseFloat(v.I_SEQ) - 1 === index
				)[0].PACKAGE_ID
				const PACKAGE_YN = ['047', '048', '049', '050'].includes(
					IT_INPUT.filter(v => parseFloat(v.I_SEQ) - 1 === index)[0].PACKAGE_ID
				)
					? 'Y'
					: 'N'
				const PACKAGE_SEQ = IT_INPUT.filter(
					v => parseFloat(v.I_SEQ) - 1 === index
				)[0].PACKAGE_SEQ
				const COMBI_NO = IT_INPUT.filter(
					v => parseFloat(v.I_SEQ) - 1 === index
				)[0].COMBI_NO
				const COMBI_SEQ = IT_INPUT.filter(
					v => parseFloat(v.I_SEQ) - 1 === index
				)[0].COMBI_SEQ
				const COMBI_GB = IT_INPUT.filter(
					v => parseFloat(v.I_SEQ) - 1 === index
				)[0].COMBI_GB
				const DC_RATE = IT_INPUT.filter(
					v => parseFloat(v.I_SEQ) - 1 === index
				)[0].DC_RATE
				const MPRM_CODE = p.payOptions.returnPromotionApplied
					? 'A067'
					: p.payOptions.promotion
				const ORDER_CNT = this.pkgPreChk(p, this.pkg.id).ORDER_CNT
				const DC_GUBUN = this.pkgPreChk(p, this.pkg.id).DC_GUBUN
				const PAY_MTHD = p.payOptions.regularPaymentMethod
				const CARDCOM_CD = p.payOptions.regularPayment
				const CARD_NO = p.payOptions.regularPaymentAccount
				const EXPIRE_YM = p.payOptions.regularPaymentExpireYm
				const KATR2 =
					this.$store.getters['verify/customerType'] === 'L' ? '2' : '1'
				const OWNER_NM = this.$store.getters['verify/custName']
				let ssn = this.$store.getters['verify/ssn']
				ssn = ssn.substr(2, ssn.length)
				const SSN_NO = KATR2 === '2' ? this.$store.getters['verify/bizNo'] : ssn
				const PAY_DD = p.payOptions.regularPaymentDay
				const PAY_CUST_ID = this.$store.state['verify'].customer?.custNo || ''
				const CMS_YN = 'Y'
				const CARDCOM_NM = p.payOptions.regularPaymentCompanyName
				const ACPT_CODE = p.payOptions.acptCode
				const MONTHS_CODE = p.payOptions.monthsCode
				const RATE_CODE = p.payOptions.rateCode
				const INS_AMT = p.esProd.PRICE - (p.payOptions.acptAmt ?? 0)
				const SAFEKEY =
					KATR2 === '2'
						? p.payOptions.ET_ZSDS5205.SAFEKEY ?? SSN_NO
						: p.payOptions.ET_ZSDS5205.SAFEKEY ?? ''
				return {
					// ORDER_SEQ			,	//주문일련번호
					GOODS_CD, //제품코드
					MEM_MONTH_AMT, //월회비
					PRM_CODE, //프로모션 코드
					MONTH_AMT, //월렌탈료
					ADSVC, //부가서비스구분
					ACPT_AMT, //인수금액
					INS_MONTHS, //할부개월수
					ACPT_CODE, //인수금 조건표 코드
					MONTHS_CODE, //할부개월수 조견표 코드
					RATE_CODE, //수수료 조견표 코드
					INS_AMT, //할부원금
					M_TOT_PAY, //월 총납입금액
					PRE_MONTH, //선납여부-개월수(3,6,9,12)
					PRE_AMT, //선납금액
					PACKAGE_YN, //패키지 선택 된 경우 'Y', 패키지 미선택 'N'
					PACKAGE_ID, //PACKAGE ID
					PACKAGE_SEQ, //상품순번
					ORDER_CNT, //000' SAP 에서 처리
					DC_GUBUN, //일시불 : 'SPACE', 렌탈 : '2'
					PAY_MTHD, //결제구분
					CARDCOM_CD, //은행코드
					CARD_NO, //계좌/카드번호20
					EXPIRE_YM, //유효기간
					KATR2, //법인/개인구분
					OWNER_NM, //예금주
					SSN_NO, //예금주주민/사업자번호
					PAY_DD, //이체일
					PAY_CUST_ID, //입금처고객번호
					// GIRO_YN, //법인지로여부
					CMS_YN, //CMS체크여부
					CARDCOM_NM, //카드사명
					// EXTR3, //예금주 핸드폰 번호
					SAFEKEY, //인증번호
					COMBI_NO, //결합요금제 번호
					COMBI_SEQ, //결합요금제 순번
					COMBI_GB, //결합 구분
					DC_RATE, //할인율1
					MPRM_CODE //중복프로모션코드
				}
			})

			this.$store.dispatch('pay/savePayPrd', this.products)
			this.$store.dispatch('pay/savePaymentForOrder', paymentOrders)
			const po = this.$store.getters['pay/paymentOrders']
			const pay = { ...this.$data }
			const productSaleInfo = this.$store.getters['pay/getProductSaleInfo']
			const combiExistingPrd = this.$store.getters['pay/getCombiExistingPrd']
			const combiApplyPrd = this.$store.getters['pay/getCombiApplyPrd']
			const combiExistingApplyPrd = this.$store.getters[
				'pay/getCombiExistingApplyPrd'
			]
			const packagePrd = this.$store.getters['pay/getPackagePrd']
			const deliveryInfos = this.$store.getters['schedule/deliveryInfos']

			await this.saveWebOrderInfo(status, {
				RECP_AMT: this.totalSumOfRecpAmt, // 전체 수납 금액
				MONTH_AMT_DC: this.allPrdTotalAmt().saleMonthTotal, // 전체 할인 금액
				PAY_SUMMARY_AMT: {
					sumOfRecpAmt: this.allPrdTotalAmt().prdRecvTotal,
					sumOfMonthAmt: this.allPrdTotalAmt().prdMonthTotal,
					sumOfRecpDcAmt: this.allPrdTotalAmt().saleRecvTotal,
					sumOfMonthDcAmt: this.allPrdTotalAmt().saleMonthTotal,
					totalSumOfRecpAmt: this.allPrdTotalAmt().recvTotal,
					totalSumOfMonthAmt: this.allPrdTotalAmt().monthTotal
				},
				IT_PAYMENT: po,
				IT_PACKCOMBI: this.$store.getters['pay/itPackcombi'],
				prdList: this.$store.getters['prd/prdList'],
				productSaleInfo,
				combiExistingPrd,
				combiApplyPrd,
				combiExistingApplyPrd,
				packagePrd,
				deliveryInfos,
				pay
			})
			return true
		},

		// 수납SAP 전송을 위한 RECP_TP RECP_AMT2
		savePaymentForSAPRecp() {
			this.$log('RECP_AMT2 ===>>>', 1)
			this.products.map(p => {
				this.$set(p.payOptions, 'RECP_TP', '00')
				// 상품별 수납 금액
				this.$set(
					p.payOptions,
					'RECP_AMT2',
					this.displayPrdTotalAmt(p.saleInfo).prdRecvTotalNum
				)
				//일시불 별매상품 가격
				this.$set(
					p.payOptions,
					'RECP_AMT3',
					this.getSeperateSoldProductPrice(p)
				)
			})
		},

		// 별매상품 가격 (일시불)
		getSeperateSoldProductPrice(p) {
			let sumOfRecpAmt = 0
			if (p.options.prdOption2 && !this.isRentalOfSoldSeparately(p)) {
				const goodsAmt =
					p.options.prdOption2.GOODS_AMT === '0'
						? +p.options.prdOption2.PRICE
						: +p.options.prdOption2.GOODS_AMT
				sumOfRecpAmt += goodsAmt
			}
			return sumOfRecpAmt
		},

		removePrePass(p) {
			// pre-pass 할인제거
			this.$set(
				p.payOptions,
				'priceDcTypes',
				p.payOptions.priceDcTypes.filter(x => x !== '01')
			)
		},

		removeRegFeeInstallment(p) {
			// 등록비분납 서비스제거
			this.$set(
				p.payOptions,
				'priceAddTypes',
				p.payOptions.priceAddTypes.filter(x => x !== '06')
			)
			this.$set(
				p.payOptions,
				'priceDcTypes',
				p.payOptions.priceDcTypes.filter(x => x !== '06')
			)
		},

		addPrePay(p) {
			this.addIfNotExist(p.payOptions, 'priceAddTypes', '04')
			this.$set(p.payOptions, 'prepayAmt', this.calcPrepayRecpAmt2(p))
			this.addIfNotExist(p.payOptions, 'priceDcTypes', '03')
		},

		// 선납할인 제거
		removePrePay(p) {
			this.$set(
				p.payOptions,
				'priceAddTypes',
				p.payOptions.priceAddTypes.filter(x => x !== '04')
			)
			this.$set(
				p.payOptions,
				'priceDcTypes',
				p.payOptions.priceDcTypes.filter(x => x !== '03')
			)
			this.$set(p.payOptions, 'prepayAmt', 0)
		},

		isRentalOfSoldSeparately(p) {
			// 별매상품 렌탈여부
			if (p && p.options && p.options.prdOption2 && p.options.prdOption2) {
				return p.options.prdOption2.CONTRACT_ID === '2'
			}

			return false
		},

		async saveWebOrderInfo({ step, statusCode }, data) {
			const mobOrderNo = this.$store.getters['verify/mobOrderNo']
			const res = await fetchWebOrderInfoById(mobOrderNo)
			let webParams = res.resultObject
			let order = JSON.parse(webParams.orderInfoJson)
			order = Object.assign({}, order, data)
			order = Object.assign(order, {
				recp: {
					isCustSelfPay: false,
					recpInfos: {}
				}
			})
			webParams.step = step // 결제정보입력완료
			webParams.statusCode = statusCode // 수납대기
			webParams.orderInfoJson = JSON.stringify(order)
			await postWebOrderInfoById(mobOrderNo, webParams)
		},

		isInstallment(p) {
			return (
				p.options.sellType.code === 'Z004' &&
				(!p.payOptions.acptAmt ||
					p.payOptions.acptAmt % 1000 !== 0 ||
					!p.payOptions.installmentMonth)
			)
		},

		// 자체할부 여부 체크
		shouldEarnestMoneyDepositDisplayed(product) {
			const installSellTypes = ['Z004']
			const sellType = product.options.sellType.code
			return installSellTypes.includes(sellType)
		},

		initAppPage() {
			registerCallbackToApp(
				{
					cbPhysicalBack: 'cbPhysicalBack'
				},
				this
			)
		},

		async cbPhysicalBack() {
			this.goPrev()
		},

		pkgPreChk(p, id) {
			const cnt = this.products.filter(x => x.payOptions.orderType === 'G')
			let pre = p.payOptions.orderType
			if (pre === 'P' || id === '' || cnt.length === 1) {
				return {
					PACKAGE_ID: '',
					PACKAGE_YN: 'N',
					PACKAGE_SEQ: '',
					ORDER_CNT: '',
					DC_GUBUN: ''
				}
			} else {
				return {
					PACKAGE_ID: id,
					PACKAGE_YN: 'Y',
					PACKAGE_SEQ: '001',
					ORDER_CNT: '000',
					DC_GUBUN: p.esProd.CONTRACT_ID === '1' ? '' : '2'
				}
			}
		},
		promotionAppliedRentalAmt(p) {
			const baseRentalAmt = +p.esProd.MONTH_AMT
			if (p.payOptions.priceDcTypes.includes('02')) {
				return baseRentalAmt - p.payOptions.promotionDcAmt
			}
			return baseRentalAmt
		},

		// 패키지 초기화
		clearPackage() {
			this.pkg.id = ''
			this.pkg.name = ''
			this.pkg.dcRate = 0 // 일시불
			this.pkg.dcAmt = 0 // 등록비
		},

		// 이전 : 상품 선택
		async goPrev() {
			const mobOrderNo = this.$store.getters['verify/mobOrderNo']
			if (this.isParameterEmpty(mobOrderNo)) return
			let arrParam = {
				id: mobOrderNo, // 웹주문번호
				statusCode: '102'
			}
			const res = await mOrderInfoByEtc(arrParam)
			let msg = undefined
			msg = getMessageByStatusCode(res.resultObject.statusCode)
			if (msg !== undefined) {
				await this.$alert({
					message: msg
				})
				sessionStorage.removeItem('customer')
				this.$router
					.push({
						name: 'order-status',
						query: {
							user_no: sessionStorage.getItem('codyNoEnc'),
							dept_cd: this.$store.getters['common/deptCd'],
							type: this.$store.getters['common/type']
						}
					})
					.catch()
			} else {
				const message = `이전 단계로 이동하면 선택한 상품만 유지되며, 설정하신 상품의 할인정보 및 설치 정보는 저장되지 않아 다시 선택해야 합니다.
<br/>이전 단계로 이동하시겠습니까?`
				const res = await this.$alert({
					message,
					okText: '확인',
					cancelText: '취소'
				})
				if (res) {
					this.$store.dispatch('pay/delAllPrd')
					this.products = []
					const mobOrderNo = this.$store.getters['verify/mobOrderNo']
					const res = await fetchWebOrderInfoById(mobOrderNo)
					let webParams = res.resultObject
					webParams.step = '02' // 상품선택
					webParams.statusCode = '102' // 인증완료
					postWebOrderInfoById(mobOrderNo, webParams)
					this.$router.push({ name: 'prd-search' })
				}
			}
		},

		// 선택된 멤버십 정보 가져오기
		getSelectedMembershipAmt(p) {
			if (
				Array.isArray(p.options.ET_ZSDS5230) &&
				p.options.ET_ZSDS5230.length > 0
			) {
				const memamt =
					p.options.ET_ZSDS5230.find(
						x => x.USE_TP === p.options.membershipType.USE_TP
					) || {}
				return memamt
			}

			return {}
		},

		/**
		 * 자체할부 잔액할부 개월수 선택 체크
		 * @returns {boolean}
		 */
		checkInstallment() {
			const productOfZ004 = this.products.filter(p =>
				this.shouldEarnestMoneyDepositDisplayed(p)
			)
			this.$log('productOfZ004', productOfZ004)
			const index = this.products.findIndex(p => this.isInstallment(p))

			const ok = productOfZ004.every(p =>
				this.installmentValid(p, Number(index) + 1)
			)

			return ok
		},

		/**
		 * 멤버십 선납할인 선택 체크
		 * @description 상품 리스트(products)에 멤버십이 있을 경우 선납여부를 선택했는지 체크한다.
		 *              기본값 "멤버십 선택 안함"이 기본으로 바뀌며 사용하지 않는 기능이다.
		 * @returns {boolean}
		 */
		membershipPrepaymentValid() {
			const productOfZ004 = this.products.filter(p =>
				this.isSelfSinglePayment(p)
			)

			const index = this.products.findIndex(p => this.isSelfSinglePayment(p))
			const ok = productOfZ004.every(p => this.installmentValid(p, index + 1))
			const value = `order_focus_${index - 1}`

			if (!ok) {
				this.$refs[value][0].focus()
			}

			return ok
		},

		/**
		 * 상품에 대한 멤버십 선납, 자체할부 유효성 체크
		 * @param prd 상품 정보
		 * @param index products의 prd가 해당하는 index
		 * @param showMessage default = true
		 * @returns {boolean}
		 */
		installmentValid(prd, index, showMessage = true) {
			const value = `order_focus_${index - 1}`

			if (!prd.payOptions.acptAmt) {
				if (showMessage)
					this.$alert({ message: `주문 ${index}에 계약금을 확인하세요.` })
				this.$refs[value][0].focus()
				return false
			}
			if (prd.payOptions.acptAmt % 1000 !== 0) {
				if (showMessage)
					this.$alert({
						message: `주문 ${index}에 계약금을 1,000원 단위로 입력하세요.`
					})
				this.$refs[value][0].focus()
				return false
			}
			if (!prd.payOptions.installmentMonth) {
				if (showMessage) {
					this.$alert({
						message: `주문 ${index}에 잔액할부기간을 선택하세요.`
					})
					this.$refs[value][0].focus()
					return false
				}
			}
			return true
		},

		// 선택시 특수문자 제거
		handleMinMaxAcptAmtFocus(p) {
			this.$log('FOCUS', p)
			const temp = p.payOptions.displayAcptAmt.toString()
			p.payOptions.displayAcptAmt = temp.replace(/[^0-9]/g, '')
		},

		/**
		 * 프로모션 체크
		 * @returns {boolean}
		 * @description 프로모션 리스트가 있고, 프로모션을 선택하지 않은 경우 confirm 출력
		 *              확인 : 진행
		 *              취소 : 해당 주문으로 이동
		 */
		async checkPromotion() {
			const promotionPrdList = []
			const promotionPrdIndexList = []

			this.products.forEach((v, i) => {
				if (
					v.payOptions.ET_ZSDS5204.length > 0 &&
					(v.payOptions.promotion === '' ||
						v.payOptions.promotion === undefined)
				) {
					promotionPrdList.push({ ...v })
					promotionPrdIndexList.push(i)
				}
			})

			if (promotionPrdIndexList.length > 0) {
				let displayText = ''
				promotionPrdIndexList.forEach((v, i) => {
					if (i === 0) {
						displayText += `${v + 1}`
					} else {
						displayText += `, 주문${v + 1}`
					}
				})

				const result = await this.$confirm({
					message: `주문${displayText}에 선택할 수 있는 프로모션이 있는데 선택되지 않았습니다. 그냥 진행하시겠습니까`,
					okText: '확인',
					cancelText: '취소'
				})

				if (!result) {
					const value = `order_focus_${promotionPrdIndexList[0]}`
					this.$refs[value][0].focus()
					return result
				}

				return result
			} else {
				return true
			}
		},
		preSubGuideOpen() {
			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
				this.popPreSubsShow = true
			})
		},
		// 사전 계약 관리판매인 변경 체크
		async preContractSaleChk() {
			if (!this.isPreContract) return true // 사전계약이 아니면 Pass

			const preOrderList = []
			const products = this.$store.getters['pay/payProducts']
			products.forEach(product => {
				if (product.options.preOrder !== undefined)
					preOrderList.push(product.options.preOrder.ORDER_NO)
			})

			if (preOrderList.length > 0) {
				return await getPreContractSalesChk({
					saleCd: this.$store.getters['common/codyNo'],
					orderNos: [...preOrderList],
					mobOrderNo: this.$store.getters['verify/mobOrderNo']
				})
					.then(async res => {
						const { result } = res.resultObject
						if (result !== 'T') {
							// 주문 삭제
							await this.$alert({
								message:
									'선택된 기존계정의 관리판매인이 변경된 것으로 확인되어 해당 주문이 삭제되었습니다. '
							})
							const mobOrderNo = this.$store.getters['verify/mobOrderNo']

							const res = await fetchWebOrderInfoById(mobOrderNo)
							const webParams = res.resultObject
							webParams.statusCode = '203'

							postWebOrderInfoById(mobOrderNo, webParams).then(postRes => {
								this.$log('postRes: ', JSON.stringify(postRes))
								// 모바일 주문현황으로 이동
								this.$router.push({ name: 'order-status' })
							})
							return false
						} else {
							return true
						}
					})
					.catch(err => {
						this.$log(err)
						this.$alert(
							'일시적인 통신 장애로 서비스에 접속할 수 없습니다. 문제가 계속된다면, 담당자에게 문의해 주세요.'
						)
					})
			}

			return true
		},
		festaApplyProducts(festaData) {
			const newData = this.$store.getters['pay/getCombiApplyPrd']
			const changedISeq = []

			const festaTrueData = festaData.packApplyPrd.filter(v => v.festaActive)
			let isNotValid = false
			festaTrueData.forEach(v => {
				const prdIdx = parseFloat(v.I_SEQ) - 1 // 상품 INDEX
				// 프로모션 체크
				if (
					this.products[prdIdx].payOptions.promotion &&
					!['A141', ''].includes(this.products[prdIdx].payOptions.promotion)
				) {
					isNotValid = true
				} else if (
					this.products[prdIdx].payOptions.downRentalPrepayAmt &&
					this.products[prdIdx].payOptions.downRentalPrepayAmt !== '0'
				) {
					isNotValid = true
				}
			})

			if (isNotValid) {
				this.$alert({
					message:
						'선택되어있는 할인제도와 중복적용 할 수 없습니다.판매 정책을 확인해주세요'
				})
				return
			}

			festaData.packApplyPrd.forEach(v => {
				if (changedISeq.includes(v.I_SEQ)) return
				const chageDataIdx = newData.findIndex(e => v.I_SEQ === e.I_SEQ)
				const prdIdx = parseFloat(v.I_SEQ) - 1 // 상품 INDEX
				if (v.festaActive) {
					if (chageDataIdx >= 0) {
						this.$set(newData, chageDataIdx, v)
						changedISeq.push(v.I_SEQ)

						this.$set(
							this.products[prdIdx].payOptions.ET_INPUT,
							'PACKAGE_SEQ',
							v.PACKAGE_SEQ
						)
						this.$set(
							this.products[prdIdx].payOptions.ET_INPUT,
							'PACKAGE_RATE',
							v.PACKAGE_RATE
						)
						this.$set(
							this.products[prdIdx].payOptions.ET_INPUT,
							'PACKAGE_ID',
							v.PACKAGE_ID
						)
					}
					this.$set(newData[chageDataIdx], 'festaActive', true)
					this.$set(newData[chageDataIdx], 'packActive', false)
					this.$set(newData[chageDataIdx], 'combiActive', false)

					this.$set(
						this.products[prdIdx].payOptions,
						'festaActive',
						v.festaActive
					)
					this.$set(
						this.products[prdIdx].payOptions,
						'packActive',
						v.packActive
					)
					this.$set(
						this.products[prdIdx].payOptions,
						'combiActive',
						v.combiActive
					)

					if (v.SALE_ID === '0043') {
						this.handleOrderTypeSelected(prdIdx, {
							code: 'FP',
							text: 'PRE-PASS'
						})
						this.$set(this.products[prdIdx].payOptions, 'orderType', 'FP')
					} else {
						this.handleOrderTypeSelected(prdIdx, this.orderTypeItems1[1])
						this.$set(this.products[prdIdx].payOptions, 'orderType', 'G')
					}
				} else {
					if (v.SALE_ID === '0043') {
						this.handleOrderTypeSelected(prdIdx, this.orderTypeItems1[0])
						this.$set(this.products[prdIdx].payOptions, 'orderType', 'P')
					} else {
						this.handleOrderTypeSelected(prdIdx, this.orderTypeItems1[1])
						this.$set(this.products[prdIdx].payOptions, 'orderType', 'G')
					}
					this.$set(this.products[prdIdx].payOptions, 'festaActive', false)
					this.$set(newData[chageDataIdx], 'festaActive', false)
				}

				this.handleFecthPayOption(prdIdx)
			})

			this.$store.dispatch('pay/saveApplyPrdAll', newData)
			this.dcInfo()
		},
		fifteenApplyProducts(packData) {
			const PACK_DATA = packData
			const packApplyNewPrd = []

			// 적용신청에서 결합 가능한 상품
			PACK_DATA.packApplyPrd.forEach(v => {
				if (v.type === 'new') {
					packApplyNewPrd.push({ ...v })
				}
			})
			this.$store.dispatch('pay/saveApplyPrdAll', packApplyNewPrd)

			this.packDataApplyPrd(PACK_DATA, 'fifteen')
			this.dcInfo()
		},
		packDataApplyPrd(PACK_DATA, gubun) {
			PACK_DATA.packApplyPrd.forEach(v => {
				// 적용신청 된 신규 주문 정보 갱신
				const prdIdx = parseFloat(v.I_SEQ) - 1
				if (v.type === 'new') {
					if (gubun === 'pack') {
						this.products[prdIdx].payOptions.packActive = v.packActive
					} else {
						this.products[prdIdx].payOptions.fifteenActive = v.fifteenActive
					}
					this.products[prdIdx].payOptions.ET_INPUT.PACKAGE_RATE =
						v.PACKAGE_RATE

					if (v.packActive && v.PACKAGE_ID === '047') {
						// 동시구매
						this.handleOrderTypeSelected(prdIdx, {
							code: 'PK',
							text: 'PRE-PASS'
						})
						this.$set(this.products[prdIdx].payOptions, 'orderType', 'PK')
					} else if (v.festaActive && ['048', '049'].includes(v.PACKAGE_ID)) {
						this.handleOrderTypeSelected(prdIdx, {
							code: 'FP',
							text: 'PRE-PASS'
						})
						this.$set(this.products[prdIdx].payOptions, 'orderType', 'FP')
					} else if (v.fifteenActive && ['050'].includes(v.PACKAGE_ID)) {
						this.handleOrderTypeSelected(prdIdx, {
							code: 'FT',
							text: 'PRE-PASS'
						})
						this.$set(this.products[prdIdx].payOptions, 'orderType', 'FT')
					} else if (v.SALE_ID === '0043') {
						this.handleOrderTypeSelected(prdIdx, this.orderTypeItems1[0])
						this.$set(this.products[prdIdx].payOptions, 'orderType', 'P')
					} else {
						this.handleOrderTypeSelected(prdIdx, this.orderTypeItems1[1])
						this.$set(this.products[prdIdx].payOptions, 'orderType', 'G')
					}

					this.handleFecthPayOption(prdIdx)
				}
			})
		}
	}
}
</script>
